import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Text from './text';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  &:first-child {
    padding: 0 16px 0 16px;
  }
`;

const Caption = styled(Text).attrs(props => ({
  variant: props.variant || 'subtitle1',
  truncate: props.truncate,
  selectionDisabled: true
}))`
  color: inherit;
`;

export default class ListItemText extends PureComponent {

  render() {
    return (
      <Container className={this.props.className}>
        <Caption
          truncate={this.props.truncate}
          variant={this.props.variant}
        >
          {this.props.children}
        </Caption>
      </Container>
    );
  }

}