import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { TextStyles } from '../shared';

const TextBase = styled.span`
  padding: 0;
  margin: 0;
  color: ${props => props.textColor || props.theme.black};
  ${props => props.truncate && css`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `}
  ${props => props.selectionDisabled && css`
    user-select: none;
  `}
`;

const Headline1 = styled(TextBase)`
  padding: 0;
  margin: 0;
  ${TextStyles.h1}
`;

const Headline2 = styled(TextBase)`
  ${TextStyles.h2}
`;

const Headline3 = styled(TextBase)`
  ${TextStyles.h3}
`;

const Headline4 = styled(TextBase)`
  ${TextStyles.h4}
`;

const Headline5 = styled(TextBase)`
  ${TextStyles.h5}
`;

const Headline6 = styled(TextBase)`
  ${TextStyles.h6}
`;

const Subtitle1 = styled(TextBase)`
  ${TextStyles.subtitle1}
`;

const Subtitle2 = styled(TextBase)`
  ${TextStyles.subtitle2}
`;

const Body1 = styled(TextBase)`
  ${TextStyles.body1}
`;

const Body2 = styled(TextBase)`
  ${TextStyles.body2}
`;

const Button1 = styled(TextBase)`
  ${TextStyles.button1}
`;

const Button2 = styled(TextBase)`
  ${TextStyles.button2}
`;

const Caption = styled(TextBase)`
  ${TextStyles.caption}
`;

const Overline = styled(TextBase)`
  ${TextStyles.overline}
`;

export default class Text extends PureComponent {

  render() {
    const props = {
      ...this.props,
      id: null
    };
    const variant = this.props.variant || 'body1';
    switch (variant) {
      case 'h1':
        return <Headline1 as='h1' {...props} ref={this.props.forwardedRef}>{this.props.children}</Headline1>;
      case 'h2':
        return <Headline2 as='h2' {...props} ref={this.props.forwardedRef}>{this.props.children}</Headline2>;
      case 'h3':
        return <Headline3 as='h3' {...props} ref={this.props.forwardedRef}>{this.props.children}</Headline3>;
      case 'h4':
        return <Headline4 as='h4' {...props} ref={this.props.forwardedRef}>{this.props.children}</Headline4>;
      case 'h5':
        return <Headline5 as='h5' {...props} ref={this.props.forwardedRef}>{this.props.children}</Headline5>;
      case 'h6':
        return <Headline6 as='h6' {...props} ref={this.props.forwardedRef}>{this.props.children}</Headline6>;
      case 'subtitle1':
        return <Subtitle1 {...props} ref={this.props.forwardedRef}>{this.props.children}</Subtitle1>;
      case 'subtitle2':
        return <Subtitle2 {...props} ref={this.props.forwardedRef}>{this.props.children}</Subtitle2>;
      case 'body1':
        return <Body1 {...props} ref={this.props.forwardedRef}>{this.props.children}</Body1>;
      case 'body2':
        return <Body2 {...props} ref={this.props.forwardedRef}>{this.props.children}</Body2>;
      case 'button1':
        return <Button1 {...props} ref={this.props.forwardedRef}>{this.props.children}</Button1>;
      case 'button2':
        return <Button2 {...props} ref={this.props.forwardedRef}>{this.props.children}</Button2>;
      case 'caption':
        return <Caption {...props} ref={this.props.forwardedRef}>{this.props.children}</Caption>;
      case 'overline':
        return <Overline {...props} ref={this.props.forwardedRef}>{this.props.children}</Overline>;
    }
  }

}