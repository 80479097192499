import React, { Component } from 'react';

export default class PlusIcon extends Component {

  render() {
    return (
      <svg height='24' viewBox='0 0 24 24' width='24' className={this.props.className} fill={this.props.color}>
        <path d='M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z'/>
      </svg>
    );
  }

}