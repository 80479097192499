import React, { Component } from 'react';

export default class MinusIcon extends Component {

  render() {
    return (
      <svg height='24' viewBox='0 0 24 24' width='24' className={this.props.className} fill={this.props.color}>
        <path d='M19,13H5V11H19V13'/>
      </svg>
    );
  }

}