export default {

  GRAY_10: '#EDEDED',
  GRAY_15: '#E7E7E7',
  GRAY_20: '#D3D3D3',
  GRAY_30: '#C5C5C5',
  GRAY_40: '#B5B5B5',
  GRAY_50: '#858585',
  GRAY_60: '#6B6B6B',
  GRAY_70: '#525252',
  GRAY_75: '#484848',
  GRAY_80: '#373737',

  BLACK: '#000000',
  WHITE: '#ffffff',

  ERROR_10: '#FFD1D4',
  ERROR_20: '#FFADB2',
  ERROR_30: '#FF8B92',
  ERROR_40: '#FF2C38',
  ERROR_50: '#AF000C',
  ERROR_70: '#800F16',

  WARNING_10: '#FFF9CF',
  WARNING_20: '#FFF3AB',
  WARNING_30: '#FFED87',
  WARNING_40: '#FFD426',
  WARNING_50: '#D6B223',
  WARNING_70: '#806F0B',

  INFO_10: '#D3F5FA',
  INFO_20: '#B0EDF5',
  INFO_30: '#8FE3EF',
  INFO_40: '#31C2D6',
  INFO_50: '#349EAD',
  INFO_70: '#136571',

  SUCCESS_10: '#E5FFD8',
  SUCCESS_20: '#D0FFBA',
  SUCCESS_30: '#BCFE9D',
  SUCCESS_40: '#82F34D',
  SUCCESS_50: '#51B324',
  SUCCESS_70: '#3F7F21',

  COLOR_00: '#EAF8F4',
  COLOR_10: '#D4F2EA',
  COLOR_20: '#8FE1CB',
  COLOR_30: '#34C8A0',
  COLOR_40: '#29BD95',
  COLOR_50: '#027E5D',
  COLOR_70: '#015630',

  COLOR_NEUTRAL_00: '#F1F3F1',
  COLOR_NEUTRAL_10: '#E5E8E4',
  COLOR_NEUTRAL_20: '#BFC8BF',
  COLOR_NEUTRAL_30: '#8C9D8A',
  COLOR_NEUTRAL_40: '#7E8A7C',
  COLOR_NEUTRAL_50: '#586856',
  COLOR_NEUTRAL_70: '#364034'
};