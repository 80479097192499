import React, { PureComponent } from 'react';
import styled from 'styled-components';
import BaseButton from './base-button';

const Container = styled(BaseButton).attrs(props => ({
  minWidth: props.minWidth || '24px',
  padding: props.padding || '0',
  focusedPadding: props.focusedPadding || '0',
  textColor: props.textColor || (!props.destructive ? props.theme.gray70 : props.theme.error50),
  backgroundColor: props.backgroundColor || props.theme.transparent,
  disabledBackgroundColor: props.disabledBackgroundColor || props.theme.transparent,
  hoveredTextColor: props.hoveredTextColor || (!props.destructive ? props.theme.gray70 : props.theme.error50),
  hoveredBackgroundColor: props.hoveredBackgroundColor || (!props.destructive ? props.theme.gray10 : props.theme.error10),
  pressedTextColor: props.pressedTextColor || (!props.destructive ? props.theme.gray80 : props.theme.error70),
  pressedBackgroundColor: props.pressedBackgroundColor || (!props.destructive ? props.theme.gray20 : props.theme.error20),
  focusedBorderColor: props.focusedBorderColor || (!props.destructive ? props.theme.primary50 : props.theme.error70),
  progressIndicatorColor: props.progressIndicatorColor || (!props.destructive ? props.theme.primary40 : props.theme.error50)
}))``;

class IconButton extends PureComponent {

  render() {
    const { forwardedRef, icon, ...props } = this.props;
    return (
      <Container
        ref={forwardedRef}
        trailingIcon={icon}
        {...props}
      >
        {this.props.children}
      </Container>
    );
  }

}

export default React.forwardRef((props, ref) => <IconButton forwardedRef={ref} {...props} />);