import CartActionTypes from './action-types';
import { ValidationResult, required, email, check } from '../../ui';

const initialState = {
  orderKey: null,
  items: [],
  deliveryCostLoading: false,
  deliveryCost: null,
  deliveryCostWithoutDiscount: null,
  availableOmnivaLocations: [],
  filteredOmnivaLocations: [],
  omnivaLocation: null,
  paymentType: null,
  customerName: '',
  customerSurname: '',
  customerPhone: '',
  customerEmail: '',
  agreeWithRules: false,
  validationMode: 'touched',
  validationResult: new ValidationResult(),
  validationRules: [
    required('omnivaLocation'),
    required('paymentType'),
    required('customerName'),
    required('customerSurname'),
    required('customerPhone'),
    required('customerEmail'),
    check('agreeWithRules'),
    email('customerEmail')
  ]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CartActionTypes.ADD_CART_ITEM:
      return {
        ...state,
        items: [...state.items]
      };
    case CartActionTypes.DELETE_CART_ITEM:
      return {
        ...state,
        items: [...state.items]
      };
    case CartActionTypes.UPDATE_CART_ITEM_AMOUNT:
      return {
        ...state,
        items: [...state.items]
      };
    case CartActionTypes.FINISH_LOADING_OMNIVA_LOCATIONS:
      return {
        ...state,
        availableOmnivaLocations: [...action.locations],
        filteredOmnivaLocations: [...action.locations]
      };
    case CartActionTypes.SELECT_CART_OMNIVA_LOCATION:
      return {
        ...state,
        omnivaLocation: action.omnivaLocation
      };
    case CartActionTypes.SEARCH_CART_OMNIVA_LOCATION:
      return {
        ...state,
        filteredOmnivaLocations: [...action.filteredOmnivaLocations]
      };
    case CartActionTypes.SELECT_CART_PAYMENT_TYPE:
      return {
        ...state,
        paymentType: action.paymentType
      };
    case CartActionTypes.UPDATE_CART_FORM:
      return {
        ...state,
        ...action.cart
      };
    case CartActionTypes.UPDATE_CART_VALIDATION_RESULT:
      return {
        ...state,
        validationResult: action.validationResult
      };
    case CartActionTypes.LOAD_CART:
      return {
        ...state,
        orderKey: action.cart?.orderKey,
        customerName: action.cart?.customerName,
        customerSurname: action.cart?.customerSurname,
        customerPhone: action.cart?.customerPhone,
        customerEmail: action.cart?.customerEmail,
        paymentType: action.cart?.paymentType,
        omnivaLocation: action.cart?.omnivaLocation,
        deliveryCost: action.cart?.deliveryCost,
        deliveryCostWithoutDiscount: action.cart?.deliveryCostWithoutDiscount,
        items: action.cart?.items
      };
    case CartActionTypes.FINISH_LOAD_CART_IMAGE:
      return {
        ...state,
        items: action.items
      };
    case CartActionTypes.START_LOAD_CART_DELIVERY_PRICE:
      return {
        ...state,
        deliveryCostLoading: true
      };
    case CartActionTypes.LOAD_CART_DELIVERY_PRICE:
      return {
        ...state,
        deliveryCostLoading: false,
        deliveryCost: action.price?.price,
        deliveryCostWithoutDiscount: action.price?.priceWithoutDiscount
      };
    case CartActionTypes.CHECK_CART_AGREE_WITH_RULES:
      return {
        ...state,
        agreeWithRules: action.value
      };
    default:
      return state;
  }
};

