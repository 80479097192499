import React, { PureComponent } from 'react';
import styled from 'styled-components';
import StackLayout from '../layouts/stack-layout';
import { ValidationResult } from '../../validation';
import { ErrorMessage } from '.';

const ValidationMode = {
  All: 'all',
  Touched: 'touched'
};

const Container = styled.form`
  display: flex;
  padding: ${props => props.padding};
`;

export default class Form extends PureComponent {

  static defaultProps = {
    validationMode: ValidationMode.Touched,
    padding: '16px'
  };

  constructor() {
    super();
    this.state = {
      showAllErrors: false,
      dirty: false,
      touchedFields: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    let errorMessage = '';
    if (this.props.validationResult && this.props.validationResult.errorMessage) {
      errorMessage = this.props.validationResult.errorMessage;
    }
    if (this.props.errorMessage) {
      errorMessage = this.props.errorMessage;
    }
    return (
      <Container
        id={this.props.id}
        padding={this.props.padding}
        onSubmit={this.handleSubmit}
      >
        <StackLayout
          orientation='vertical'
          rowGap='16px'
        >
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          {this.decorate(this.props.children)}
        </StackLayout>
      </Container>
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    const modifiedState = {
      showAllErrors: true
    };
    this.setState(state => {
      if (state.dirty && (!this.props.validationResult || this.props.validationResult.valid)) {
        modifiedState.dirty = false;
      }
      return modifiedState;
    }, () => {
      if (this.props.onSubmit) {
        this.props.onSubmit();
      }
      if (this.props.onDirtyChange && 'dirty' in modifiedState) {
        this.props.onDirtyChange({
          dirty: modifiedState.dirty
        });
      }
    });
  }

  decorate(children) {
    return React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return (
          <child.type
            loading={this.props.loading}
            {...child.props}
            ref={child.ref}
          >
            {this.decorateChildren(child.props.children)}
          </child.type>
        );
      }
      return child;
    });
  }

  decorateChildren(children) {
    return React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        let props = { ...child.props };
        if (child.props.readOnly === undefined && this.props.loading) {
          props.readOnly = true;
        }
        if (props.name && this.props.validationResult) {
          const showError = this.state.showAllErrors ||
            this.props.validationMode === ValidationMode.All ||
            this.props.validationMode === ValidationMode.Touched && this.state.touchedFields.includes(props.name);
          if (showError) {
            const validationResult = this.props.validationResult instanceof ValidationResult
              ? this.props.validationResult
              : new ValidationResult(this.props.validationResult);
            props.errorMessage = validationResult.getErrorMessage(props.name);
          }
        }
        if (props.name && this.props.validationMode === ValidationMode.Touched) {
          const onChange = props.onChange;
          props.onChange = e => {
            let modifiedState = null;
            this.setState(state => {
              if (!state.dirty) {
                modifiedState = {
                  dirty: true
                };
              }
              return modifiedState;
            }, () => {
              if (this.props.onDirtyChange && modifiedState) {
                this.props.onDirtyChange({
                  dirty: modifiedState.dirty
                });
              }
            });
            if (onChange) {
              onChange(e);
            }
          };
          const onBlur = props.onBlur;
          props.onBlur = e => {
            this.setState(state => {
              if (!state.touchedFields.includes(props.name)) {
                return {
                  touchedFields: [...state.touchedFields, props.name]
                };
              }
              return null;
            });
            if (onBlur) {
              onBlur(e);
            }
          };
        }
        return (
          <child.type
            {...props}
            ref={child.ref}
          >
            {this.decorateChildren(props.children)}
          </child.type>
        );
      }
      return child;
    });
  }

}