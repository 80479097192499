import React, { PureComponent } from 'react';
import { KeyCodes } from '../shared';

export default function withKeyboardEvents(WrappedComponent) {

  class ComponentWithKeyboardEvents extends PureComponent {

    constructor() {
      super();
      this.childrenRef = new Map();
      this.state = {
        activatedItemId: null
      };
      this.handleClick = this.handleClick.bind(this);
      this.handleKeyDown = this.handleKeyDown.bind(this);
      this.handleFocus = this.handleFocus.bind(this);
      this.handleBlur = this.handleBlur.bind(this);
      this.handleReset = this.handleReset.bind(this);
    }

    componentDidMount() {
      if (this.props.useGlobalKeyEvents) {
        document.addEventListener('keydown', this.handleKeyDown);
      }
    }

    componentWillUnmount() {
      if (this.props.useGlobalKeyEvents) {
        document.removeEventListener('keydown', this.handleKeyDown);
      }
    }

    render() {
      const { forwardedRef, ...rest } = this.props;
      return (
        <WrappedComponent
          ref={forwardedRef}
          tabIndex='0'
          {...rest}
          onKeyDown={this.props.useGlobalKeyEvents ? null : this.handleKeyDown}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onReset={this.handleReset}
        >
          {this.decorate(this.props.children, null)}
        </WrappedComponent>
      );
    }

    decorate(children, parentId) {
      children = this.flatten(children);
      return React.Children.map(children, (child, index) => {
        const key = this.getItemKey(child.props, index, parentId);
        if (child.props.nested) {
          return (
            <child.type
              {...child.props}
              key={key}
            >
              {this.decorate(child.props.children, key)}
            </child.type>
          );
        } else {
          return (
            <child.type
              {...child.props}
              key={key}
              id={key}
              activated={key === this.state.activatedItemId}
              clickable={!!child.props.onClick}
              onClick={e => {
                this.handleClick(e);
                if (child.props.onClick) {
                  child.props.onClick(e);
                }
              }}
              ref={value => {
                this.childrenRef.set(key, value);
                this.setRef(child.ref, value);
              }}
            >
              {child.props.children}
            </child.type>
          );
        }
      });
    }

    handleKeyDown(e) {
      if (e.key === KeyCodes.ARROW_UP) {
        e.preventDefault();
        this.handleArrowUp();
      } else if (e.key === KeyCodes.ARROW_DOWN) {
        e.preventDefault();
        this.handleArrowDown();
      } else if (e.key === KeyCodes.ENTER) {
        e.preventDefault();
        this.handleEnter();
      }
    }

    handleArrowUp() {
      this.activatePrevious();
    }

    handleArrowDown() {
      this.activateNext();
    }

    handleClick(e) {
      if (this.state.activatedItemId !== e.id) {
        this.setState({
          activatedItemId: null
        });
      }
    }

    handleEnter() {
      if (this.state.activatedItemId !== undefined && this.state.activatedItemId !== null) {
        const child = this.childrenRef.get(this.state.activatedItemId);
        if (child && child.props && child.props.onClick) {
          child.props.onClick({
            id: child.props.id
          });
        }
      }
    }

    handleFocus(e) {
      if (this.props.onFocus) {
        this.props.onFocus(e);
      }
    }

    handleBlur(e) {
      this.setState({
        activatedItemId: null
      }, () => {
        if (this.props.onBlur) {
          this.props.onBlur(e);
        }
      });
    }

    handleReset() {
      this.setState({
        activatedItemId: null
      });
    }

    activateNext() {
      this.setState(state => {
        const children = this.flatten(this.props.children);
        const ids = this.getItemIds(children);
        if (ids.length > 0) {
          let index = ids.indexOf(state.activatedItemId);
          if (index < ids.length - 1) {
            index = index !== -1
              ? index + 1
              : 0;
            return {
              activatedItemId: ids[index]
            };
          }
        }
        return null;
      });
    }

    activatePrevious() {
      this.setState(state => {
        const children = this.flatten(this.props.children);
        const ids = this.getItemIds(children);
        if (ids.length > 0) {
          let index = ids.indexOf(state.activatedItemId);
          if (index > 0 || index === -1) {
            index = index !== -1
              ? index - 1
              : 0;
            return {
              activatedItemId: ids[index]
            };
          }
        }
        return null;
      });
    }

    setRef(ref, value) {
      if (ref) {
        if (typeof ref === 'function') {
          ref(value);
        } else {
          ref.current = value;
        }
      }
    }

    flatten(children) {
      let result = [];
      React.Children.forEach(children, child => {
        if (child) {
          if (child.type === React.Fragment) {
            result = result.concat(this.flatten(child.props.children));
          } else {
            result.push(child);
          }
        }
      });
      return result;
    }

    getItemKey(props, index, parentId) {
      let key = index;
      if (props.id) {
        key = props.id;
      } else if (parentId) {
        key = `${parentId}:${index}`;
      }
      return key;
    }

    getItemIds(children, parentId) {
      let ids = [];
      React.Children.forEach(children, (child, index) => {
        const key = this.getItemKey(child.props, index, parentId);
        if (!child.props.nested && !child.props.separator && !child.props.disabled) {
          ids.push(key);
        } else if (child.props.expanded) {
          ids = ids.concat(this.getItemIds(child.props.children, key));
        }
      });
      return ids;
    }

  }

  return React.forwardRef((props, ref) => {
    return <ComponentWithKeyboardEvents {...props} forwardedRef={ref} />;
  });

}