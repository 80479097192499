import React, { PureComponent } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { IconButton, Text } from '.';
import { KeyCodes, withElevation, withFocusTrap, respondTo } from '../shared';
import { CloseIcon } from '../icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 9999;
  ${props => !props.visible && css`
    display: none;
  `}
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${props => props.theme.neutral00};
  opacity: 0.6;
  ${props => props.closing && css`
    animation: ${fadeOut} 0.2s;
  `}
  ${props => props.opening && css`
    animation: ${fadeIn} 0.2s;
  `}
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 0;
  }
`;

const scaleIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  90% {
    transform: scale(1.015);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const scaleOut = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  10% {
    transform: scale(1.015);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
`;

const InnerContainer = withElevation(8, styled.div`
  position: relative;
  background-color: ${props => props.theme.white};
  width: 320px;
  ${respondTo('xs', css`
    width: auto;
    min-width: ${props => props.$width ? props.$width : '600px'};
    max-width: calc(100% - 64px);
  `)}
  ${props => props.$width && css`
    min-width: ${props.$width};
  `};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  max-height: calc(100% - 64px);
  opacity: 1;
  ${props => props.opening && css`
    animation: ${scaleIn} 0.2s;
  `}
  ${props => props.closing && css`
    animation: ${scaleOut} 0.2s;
  `}
`);

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  border-bottom: 1px solid ${props => props.borderColor || props.theme.primary20};
  box-sizing: border-box;
  padding: 0 8px 0 16px;
  flex-shrink: 0;
`;

const Title = styled(Text).attrs(props => ({
  variant: 'h3',
  selectionDisabled: true,
  textColor: props.textColor || props.theme.gray80
}))`
  flex-grow: 1;
`;

const CloseButton = styled(IconButton)``;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
`;

class Dialog extends PureComponent {

  static defaultProps = {
    id: 'dialog'
  };

  constructor(props) {
    super();
    this.state = {
      opening: !props.closed,
      closing: false,
      visible: !props.closed
    };
    this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handlePointerDown = this.handlePointerDown.bind(this);
    this.handleAnimationEnd = this.handleAnimationEnd.bind(this);
    this.innerContainerRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, currentState) {
    if (!nextProps.closed && !currentState.visible) {
      return {
        opening: true,
        closing: false,
        visible: true
      };
    } else if (nextProps.closed && currentState.visible) {
      return {
        opening: false,
        closing: true
      };
    }
    return null;
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('pointerdown', this.handlePointerDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('pointerdown', this.handlePointerDown);
  }

  render() {
    return (
      <Container visible={this.state.visible}>
        <Overlay closing={this.state.closing} opening={this.state.opening} />
        <InnerContainer
          ref={this.innerContainerRef}
          {...this.state}
          $width={this.props.width}
          onAnimationEnd={this.handleAnimationEnd}
        >
          {!this.props.hideHeader &&
            <Header borderColor={this.props.borderColor}>
              <Title
                id='dialog-title'
                textColor={this.props.textColor}
              >
                {this.props.title}
              </Title>
              <CloseButton
                id='close-button'
                icon={CloseIcon}
                disabled={this.props.disabled}
                onClick={this.handleCloseButtonClick}
              />
            </Header>
          }
          <Content>
            {this.props.children}
          </Content>
        </InnerContainer>
      </Container>
    );
  }

  handleCloseButtonClick() {
    if (!this.props.closed) {
      this.setState({
        closing: true
      }, () => {
        if (this.props.onClose) {
          this.props.onClose();
        }
      });
    }
  }

  handleKeyDown(e) {
    if (e.key === KeyCodes.ESCAPE && !this.props.closed && !this.state.opening && !this.state.closing && !this.props.disabled) {
      this.setState({
        closing: true
      }, () => {
        if (this.props.onClose) {
          this.props.onClose();
        }
      });
    }
  }

  handlePointerDown(e) {
    if (this.innerContainerRef.current && !this.innerContainerRef.current.contains(e.target)
      && !this.props.closed && !this.state.opening && !this.state.closing && !this.props.disabled
    ) {
      this.setState({
        closing: true
      }, () => {
        if (this.props.onClose) {
          this.props.onClose();
        }
      });
    }
  }

  handleAnimationEnd (e) {
    e.stopPropagation();
    if (e.target === this.innerContainerRef.current) {
      this.setState(state => {
        if (state.opening) {
          return {
            opening: false,
            visible: true
          };
        } else if (state.closing) {
          return {
            closing: false,
            visible: false
          };
        }
        return null;
      }, () => {
        if (this.props.onAnimationEnd) {
          this.props.onAnimationEnd();
        }
      });
    }
  }

}

export default withFocusTrap(Dialog);