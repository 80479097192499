import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  display: grid;
  ${props => props.columnGap && css`
    column-gap: ${props => props.columnGap};
  `}
  ${props => props.columnCount && css`
    grid-template-columns: repeat(${props => props.columnCount}, minmax(0, 1fr));
  `};
  ${props => props.columns && css`
    grid-template-columns: ${props => props.columns};
  `};
  ${props => props.rowGap && css`
    row-gap: ${props => props.rowGap};
  `}
  ${props => props.rowCount && css`
    grid-template-rows: repeat(${props => props.rowCount}, 1fr);
  `};
  ${props => props.rows && css`
    grid-template-rows: ${props => props.rows};
  `};
  ${props => props.$width && css`
    flex-grow: 0;
    flex-basis: auto;
    width: ${props.$width};
  `}
  ${props => props.$height && css`
    height: ${props.$height};
  `}
  ${props => props.backgroundColor && css`
    background-color: ${props.backgroundColor};
  `}
  ${props => props.column && css`
    grid-column-start: ${props => props.column};
  `}
  ${props => props.columnSpan && css`
    grid-column-end: span ${props => props.columnSpan};
  `}
  ${props => props.row && css`
    grid-row-start: ${props => props.row};
  `}
  ${props => props.rowSpan && css`
    grid-row-end: span ${props => props.rowSpan};
  `}
  ${props => props.margin && css`
    margin: ${props => props.margin};
  `}
`;

class GridLayout extends PureComponent {

  static defaultProps = {
    columnCount: 1,
    columnGap: '0px',
    rowCount: 1,
    rowGap: '0px'
  }

  constructor() {
    super();
  }

  render() {
    return (
      <Container
        ref={this.props.forwardedRef}
        className={this.props.className}
        margin={this.props.margin}
        columnCount={this.props.columnCount}
        columns={this.props.columns}
        columnGap={this.props.columnGap}
        rowCount={this.props.rowCount}
        rows={this.props.rows}
        rowGap={this.props.rowGap}
        $width={this.props.width}
        $height={this.props.height}
        backgroundColor={this.props.backgroundColor}
        column={this.props.column}
        columnSpan={this.props.columnSpan}
        row={this.props.row}
        rowSpan={this.props.rowSpan}
      >
        {this.props.children}
      </Container>
    );
  }

}

export default React.forwardRef((props, ref) => <GridLayout forwardedRef={ref} {...props} />);