import Validator from './validator';
import RequiredValidator from './required-validator';
import EmailValidator from './email-validator';
import NumberValidator from './number-validator';
import CheckValidator from './check-validator';

export function validateObject(...params) {
  return Validator.validateObject(...params);
}

export function required(propertyName, errorMessage) {
  return new RequiredValidator({
    propertyName: propertyName,
    errorMessage: errorMessage
  });
}

export function email(propertyName, errorMessage) {
  return new EmailValidator({
    propertyName: propertyName,
    errorMessage: errorMessage
  });
}

export function number(propertyName, errorMessage) {
  return new NumberValidator({
    propertyName: propertyName,
    errorMessage: errorMessage
  });
}

export function check(propertyName, errorMessage) {
  return new CheckValidator({
    propertyName: propertyName,
    errorMessage: errorMessage
  });
}