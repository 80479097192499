const menuItems =
  [{
  //   id: 'about-us',
  //   title: 'Apie mus',
  //   path: '/'
  // },
  // {
    id: 'shop',
    title: 'Parduotuvė',
    path: '/shop'
  }];

export default menuItems;