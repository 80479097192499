import React, { Component } from 'react';

export default class NoBleachIcon extends Component {

  render() {
    return (
      <svg width='48' height='48' viewBox='0 0 375 375' className={this.props.className} fill={this.props.color}>
        <path
          fill='none'
          stroke={this.props.color}
          strokeWidth='8'
          d='M 187.49980,75.000010 L 56.250000,318.75000 L 318.75000,318.75000 L 187.49980,75.000010 z '
        />
        <g transform='matrix(1.000000,0.000000,0.000000,1.000630,-0.450510,25.74451)'>
          <path
            fill='none'
            stroke={this.props.color}
            strokeWidth='8'
            d='M 55.799488,93.750000 L 318.75000,319.05449'
          />
          <path
            fill='none'
            stroke={this.props.color}
            strokeWidth='8'
            d='M 319.20051,93.750000 L 56.250000,319.05449'
          />
        </g>
      </svg>
    );
  }

}