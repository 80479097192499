import ApplicationActionTypes from './action-types';

const initialState = {
  activeDialog: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ApplicationActionTypes.OPEN_DIALOG:
      return {
        ...state,
        activeDialog: action.activeDialog
      };
    case ApplicationActionTypes.CLOSE_DIALOG:
      return {
        ...state,
        activeDialog: { ...state.activeDialog, closed: true }
      };
    case ApplicationActionTypes.DIALOG_CLOSED:
      return {
        ...state,
        activeDialog: null
      };
    default:
      return state;
  }
};

