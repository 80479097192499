import React, { Component } from 'react';

export default class NoDryCleanIcon extends Component {

  render() {
    return (
      <svg width='48' height='48' viewBox='0 0 375 375' className={this.props.className} fill={this.props.color}>
        <path
          fill='none'
          stroke={this.props.color}
          strokeWidth='8'
          d='M 311.48582,187.50000 C 311.49647,118.79457 255.98301,63.092151 187.50000,63.092151 C 119.01699,63.092151 63.503530,118.79457 63.514183,187.50000 C 63.503530,256.20543 119.01699,311.90785 187.50000,311.90785 C 255.98301,311.90785 311.49647,256.20543 311.48582,187.50000 L 311.48582,187.50000 z'
          transform='matrix(0.905299, 0, 0, 0.934737, 17.7564, 20.9663)'
        />
        <path
          fill='none'
          stroke={this.props.color}
          strokeWidth='8'
          d='M 55.799488,93.750000 L 318.75000,319.05449'
          transform='matrix(1.000000,0.000000,0.000000,0.825462,0.450512,30.02228)'
        />
        <path
          fill='none'
          stroke={this.props.color}
          strokeWidth='8'
          d='M 319.20051,93.750000 L 56.250000,319.05449'
          transform='matrix(1.000000,0.000000,0.000000,0.825462,0.450512,30.02228)'
        />
      </svg>
    );
  }

}