import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { KeyCodes } from '.';

const Wrapper = styled.div``;

export default function withFocusTrap (WrappedComponent) {

  return class FocusTrap extends PureComponent {

    constructor() {
      super();
      this.ref = React.createRef();
      this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
      document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
      document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown(e) {
      if (e.key === KeyCodes.TAB) {
        const focusableElements = Array.from(this.ref.current.querySelectorAll(
          'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
        ));
        if (focusableElements && focusableElements.length > 0) {
          const firstElement = focusableElements[0];
          const lastElement = focusableElements[focusableElements.length - 1];
          if (!focusableElements.includes(document.activeElement)) {
            e.preventDefault();
            firstElement.focus();
          } else if (!e.shiftKey && document.activeElement === lastElement) {
            e.preventDefault();
            firstElement.focus();
          } else if (e.shiftKey && document.activeElement === firstElement) {
            e.preventDefault();
            lastElement.focus();
          }
        }
      }
    }

    render() {
      return (
        <Wrapper ref={this.ref}>
          <WrappedComponent {...this.props} />
        </Wrapper>
      );
    }
  };

}