import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Badge, StackLayout, Text, respondTo } from '../../../ui';
import { Price } from '../../shared';
import { ShopImage } from '.';

const Container = styled(StackLayout).attrs(props => ({
  orientation: 'vertical',
  rowGap: '12px'
}))`
  position: relative;
  flex-grow: 0;
`;

const Details = styled(StackLayout).attrs(props => ({
  orientation: 'vertical',
  rowGap: '4px',
  align: 'center',
  valign: 'top'
}))``;

const PriceSeparator = styled(Text).attrs(props => ({
  variant: props.textVariant || 'subtitle1'
}))`
  color: #234828;
`;

const Title = styled(NavLink)`
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 300;
  ${respondTo('xs', css`
    font-size: 16px;
    line-height: 20px;
  `)}
  text-decoration: none;
  color: #8c8c8c;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 1px;
    bottom: -1px;
    left: 0;
    background-color: #234828;
    transition: all 0.2s ease-in-out;
    z-index: 1;
  }

  &:hover:before {
    visibility: visible;
    width: 100%;
  }

  &:hover {
    text-decoration: none;
  }
`;

const SizeLabel = styled(Text).attrs(props => ({
  variant: 'caption',
  selectionDisabled: true,
  textColor: props.theme.gray80
}))``;

export default class ShopItem extends React.PureComponent {

  constructor() {
    super();
    this.getDefaultSize = this.getDefaultSize.bind(this);
  }

  render() {
    const defaultSize = this.props.sizes && this.props.sizes.length > 0 ? this.getDefaultSize() : null;
    const minPrice = this.props.sizes && this.props.sizes.length > 0 ? this.getMinPriceSize(this.props.sizes) : null;
    const maxPrice = this.props.sizes && this.props.sizes.length > 0 ? this.getMaxPriceSize(this.props.sizes) : null;
    return (
      <Container>
        <ShopImage
          alt={this.props.name}
          src={`/api/products/${this.props.productKey}/image`}
          to={`/product/${this.props.productKey}`}
        />
        {defaultSize.priceDiscount > 0 && <Badge value={`-${defaultSize.priceDiscount}%`} top='8px' right='8px' color={'#92C999'} />}
        <Details>    
          <Title to={`/product/${this.props.productKey}`}>
            {this.props.name}
          </Title>
          {minPrice && maxPrice && minPrice.price !== maxPrice.price &&
            <StackLayout orientation='horizontal' align='center' columnGap='8px'>
              <Price price={minPrice.price}/>
              <PriceSeparator>-</PriceSeparator>
              <Price price={maxPrice.price}/>
            </StackLayout>
          }
        </Details>
      </Container>
    );
  }

  getDefaultSize() {
    const defaultSize = this.props.sizes.find(item => item.priceDiscount > 0);
    if (!defaultSize) {
      return this.props.sizes[0];
    } else {
      return defaultSize;
    }
  }

  getMinPriceSize(sizes) {
    let min = sizes[0];
    for (let i = 1; i < sizes.length; ++i) {
      if (sizes[i].price < min.price) {
        min = sizes[i];
      }
    }
    return min;
  }

  
  getMaxPriceSize(sizes) {
    let max = sizes[0];
    for (let i = 1; i < sizes.length; ++i) {
      if (sizes[i].price > max.price) {
        max = sizes[i];
      }
    }
    return max;
  }

}