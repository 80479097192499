import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { withElevation, respondTo, KeyCodes } from '../shared';

const DrawerPositions = {
  LEFT: 'left',
  RIGHT: 'right'
};

const Container = withElevation(16, styled.div`
  display: flex;
  position: fixed;
  width: 192px;
  top: 0;
  bottom: 0;
  z-index: 200;
  background-color: ${props => props.theme.white};
  transition: transform 250ms;
  ${props => props.position === DrawerPositions.LEFT && css`
    right: auto;
    left: 0;
    transform: ${props => props.closed ? 'translateX(-280px)' : 'none'};
  `}
  ${props => props.position === DrawerPositions.RIGHT && css`
    right: 0;
    left: auto;
    transform: ${props => props.closed ? 'translateX(280px)' : 'none'};
  `}
`);

const Scrim = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 250ms;
  opacity: ${props => props.visible ? '1' : '0'};
  ${respondTo('xs', css`
    display: none;
  `)}
`;

export default class Drawer extends PureComponent {

  constructor(props) {
    super();
    this.state = {
      visible: !props.closed
    };
    this.containerRef = React.createRef();
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handlePointerDown = this.handlePointerDown.bind(this);
    this.handleTransitionEnd = this.handleTransitionEnd.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('pointerdown', this.handlePointerDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('pointerdown', this.handlePointerDown);
  }

  componentDidUpdate() {
    setTimeout(() => {
      this.setState((state, props) => {
        if (!props.closed && !state.visible) {
          return {
            visible: true
          };
        }
        return null;
      });
    }, 0);
  }

  render() {
    const position = this.props.position || DrawerPositions.LEFT;
    return (!this.props.closed || this.state.visible) && (
      <>
        <Container
          ref={this.containerRef}
          className={this.props.className}
          position={position}
          closed={this.props.closed || !this.state.visible}
          onTransitionEnd={this.handleTransitionEnd}
          onKeyDown={this.props.onKeyDown}
          onKeyUp={this.props.onKeyUp}
        >
          {this.props.children}
        </Container>
        <Scrim
          visible={!this.props.closed && this.state.visible}
        />
      </>
    );
  }

  handleKeyDown(e) {
    if (e.key === KeyCodes.ESCAPE && this.props.onClose && !this.props.closed) {
      this.props.onClose();
    }
  }

  handlePointerDown(e) {
    if (this.containerRef.current && !this.containerRef.current.contains(e.target) && this.props.onClose && !this.props.closed) {
      this.props.onClose();
    }
  }

  handleTransitionEnd(e) {
    e.stopPropagation();
    if (e.target === this.containerRef.current) {
      this.setState((state, props) => {
        if (state.visible && props.closed) {
          return {
            visible: false
          };
        }
      }, () => {
        if (this.props.onTransitionEnd) {
          this.props.onTransitionEnd();
        }
      });
    }
  }

}