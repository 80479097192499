import React, { PureComponent } from 'react';

export default function withoutEventPropagation(WrappedComponent) {

  class EventPropagation extends PureComponent {

    constructor() {
      super();
      this.handleEvent = this.handleEvent.bind(this);
    }

    render() {
      return (
        <div
          onPointerUp={this.handleEvent}
          onPointerDown={this.handleEvent}
          onClick={this.handleEvent}
        >
          <WrappedComponent {...this.props} />
        </div>
      );
    }

    handleEvent(e) {
      e.stopPropagation();
    }

  }

  return React.forwardRef((props, ref) => <EventPropagation forwardedRef={ref} {...props} />);

}