import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import Menu from './menu';
import logo from '../../../logo.png';
import { Badge, BasketLargeIcon, UnderlinedIconButton, NavigationDrawer, MenuIcon, respondTo, StackLayout, Separator, elevation } from '../../../ui';

const Sentinel = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
`;

const Wrapper = styled.header`
  position: sticky;
  top: 0;
  z-index: 10;
  transition: box-shadow 250ms;
  background-color: white;
  ${props => props.stuck && css`
    ${elevation(2)}
  `}
`;

const Container = styled(StackLayout).attrs(props => ({
  orientation: 'horizontal',
  align: 'space-between'
}))`
  min-width: 312px;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px 0 0 0;
  margin-top: 8px;
  margin-bottom: 2px;
`;

const Logo = styled(NavLink)`
  position: relative;
  width: 128px;
  margin-left: 16px;

  & img {
    position: absolute;
    width: 128px;
    bottom: -8px;
  }
`;


const CartButton = styled.div`
  position: relative;
  height: 24px;
`;


const MenuButton = styled(UnderlinedIconButton)`
  height: 24px;
  display: flex;
  ${respondTo('xs', css`
    display: none;
  `)}
`;


const ButtonsContainer = styled(StackLayout).attrs(props => ({
  orientation: 'horizontal',
  align: 'right',
  columnGap: '8px'
}))`
  margin: 0 16px;
`;

const Divider = styled.div`
  min-width: 312px;
  position: absolute;
  width: 100%;
  left: 0px;
  content: " ";
  display: block;
  border-top: 1px solid #92C999;
  border-bottom: 0px solid #92C999;
  background-color: #92C999;
`;

const MenuDivider = styled(Separator)`
  display: none;
  ${respondTo('xs', css`
    
    display: flex;
  `)}
`;

export default class Header extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      stuck: false
    };
    this.handleNavigateToCart = this.handleNavigateToCart.bind(this);
    this.sentinelRef = React.createRef();
    this.handleIntersectionObserverCallback = this.handleIntersectionObserverCallback.bind(this);
    this.intersectionObserver = new IntersectionObserver(this.handleIntersectionObserverCallback);
  }

  componentDidMount() {
    this.intersectionObserver.observe(this.sentinelRef.current);
  }

  componentWillUnmount() {
    this.intersectionObserver.disconnect();
  }

  render() {
    return (
      <>
        <Wrapper stuck={this.state.stuck}>
          <Container>
            <NavigationDrawer
              items={this.props.items}
              selectedItemId={this.props.selectedMenuItemId}
              closed={this.props.drawerClosed}
              onClose={this.props.onDrawerClose}
              onItemClick={this.props.onNavidationItemSelect}
            />
            <Logo to="/">
              <img alt="EPPE Logo" src={logo} />
            </Logo>

            <ButtonsContainer>
              <Menu />
              <MenuDivider orientation='vertical'/>
              <CartButton>
                <UnderlinedIconButton icon={BasketLargeIcon} onClick={this.handleNavigateToCart}/>
                {this.props.cartCount > 0 && <Badge value={this.props.cartCount} top='-8px' right='-6px' color={'#92C999'} />}
              </CartButton>
              <MenuButton onClick={this.props.onDrawerOpen} icon={MenuIcon} />
            </ButtonsContainer>     
          </Container>
          <Divider />
        </Wrapper>
        <Sentinel ref={this.sentinelRef} />
      </>
    );
  }

  handleNavigateToCart() {
    this.props.history.push('/cart');
  }

  handleIntersectionObserverCallback(entries) {
    if (entries?.length > 0) {
      this.setState({
        stuck: !entries[0].isIntersecting
      });
    }
  }


}