import Validator from './validator';

export default class EmailValidator extends Validator {

  constructor({ propertyName, errorMessage } = {}) {
    super();
    this.propertyName = propertyName;
    if (!this.propertyName) {
      throw new Error('Property name is not set for email validator');
    }
    this.errorMessage = errorMessage || 'Invalid email';
  }

  validate(validationContext) {
    return this.validateProperty(validationContext.target, this.propertyName, x => {
      if (this.hasValue(x.value)) {
        if (x.value && /^[\w.%+-]+@[\w.-]+\.\w{2,}$/.test(x.value)) {
          return true;
        }
        validationContext.addError(
          x.path,
          this.errorMessage
        );
        return false;
      }
      return true;
    });
  }

}