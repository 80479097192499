import ValidationError from './validation-error';

export default class ValidationContext {

  constructor({ target, errorMessage, errors = [] } = {}) {
    this.target = target;
    this.errorMessage = errorMessage;
    this.errors = errors;
  }

  addError(propertyName, errorMessage) {
    this.errors.push(new ValidationError({
      propertyName: propertyName,
      errorMessage: errorMessage
    }));
  }

}