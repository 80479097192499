import React from 'react';
import styled from 'styled-components';
import { Price } from '../../shared';
import { Dialog, StackLayout, Text, BaseButton } from '../../../ui';

const Container = styled(StackLayout).attrs(props => ({
  orientation: 'vertical',
  rowGap: '8px'
}))`
  margin: 8px;
`;

const Content = styled(StackLayout).attrs(props => ({
  orientation: 'horizontal',
  columnGap: '8px'
}))``;

const Image = styled.img`
  width: 128px;
  max-width: 128px;
  height: auto;
`;

const Details = styled(StackLayout).attrs(props => ({
  orientation: 'vertical',
  rowGap: '24px',
  valign: 'top',
  notGrow: true
}))``;

const Selections = styled(StackLayout).attrs(props => ({
  orientation: 'vertical',
  notGrow: true
}))``;

const Selection = styled(StackLayout).attrs(props => ({
  orientation: 'horizontal',
  columnGap: '8px',
  align: 'left'
}))``;

const Actions = styled(StackLayout).attrs(props => ({
  orientation: 'horizontal',
  align: 'space-between'
}))``;

export default class CartCheckoutDialog extends React.PureComponent {

  constructor() {
    super();
    this.handleNavigateToShop = this.handleNavigateToShop.bind(this);
    this.handleNavigateToCart = this.handleNavigateToCart.bind(this);
  }

  render() {
    const { closed, onAnimationEnd, onDialogClose, product, size, amount } = this.props;
    return (
      <Dialog
        title='Prekė sėkmingai pridėta į krepšelį'
        width='320px'
        onClose={onDialogClose}
        closed={closed}
        onAnimationEnd={onAnimationEnd}
      >
        <Container>
          <Content>
            <Image alt={product?.name} src={`/api/products/${product?.key}/image`} />
            <Details>
              <Text variant='subtitle1'>{product?.title}</Text>
              <Selections>
                <Selection>
                  <Text variant='body2'>Dydis:</Text>
                  <Text variant='subtitle2'>{size?.name}</Text>
                </Selection>
                <Selection>
                  <Text variant='body2'>Kiekis:</Text>
                  <Text variant='subtitle2'>{amount}</Text>
                </Selection>
              </Selections>
              {size &&
                <Price
                  price={size.price}
                  priceWithoutDiscount={size.price_without_discount}
                />
              }
            </Details>
          </Content>
          <Actions>
            <BaseButton text='Tęsti apsipirkimą' onClick={this.handleNavigateToShop}/>
            <BaseButton text='Apmokėti' onClick={this.handleNavigateToCart}/>
          </Actions>
        </Container>
      </Dialog>
    );
  }

  handleNavigateToShop() {
    this.props.onDialogClose();
    this.props.history.push('/shop');
  }

  handleNavigateToCart() {
    this.props.onDialogClose();
    this.props.history.push('/cart');
  }
}