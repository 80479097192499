import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import { Skeleton, StackLayout, GridLayout, respondTo } from '../../../ui';
import ShopItem from './shop-item';
import api from '../../../utils/api';

const Container = styled(GridLayout).attrs(props => ({
  align: 'center',
  rowGap: '48px',
  columnGap: '16px'
}))`
  margin: 16px;
  grid-template-columns: repeat(2,minmax(0,1fr));
  ${respondTo('xs', css`
    grid-template-columns: repeat(3,minmax(0,1fr));
  `)}
  ${respondTo('sm', css`
    grid-template-columns: repeat(4,minmax(0,1fr));
  `)}
`;

const ItemSkeleton = styled(StackLayout).attrs(props => ({
  orientation: 'vertical',
  rowGap: '12px'
}))`
  margin: 0;
`;

const ItemImageSkeleton = styled(Skeleton).attrs(props => ({
  width: '100%',
  variant: 'rect'
}))`
  padding-top: 100%;
`;

const ItemTitleSkeleton = styled(Skeleton).attrs(props => ({
  width: '100%',
  heigh: '22px',
  variant: 'text'
}))`
`;

const ItemTextSkeleton = styled(Skeleton).attrs(props => ({
  width: '100%',
  heigh: '20px',
  variant: 'text'
}))`
`;

class ShopPage extends React.PureComponent {

  constructor() {
    super();
    this.state = {
      group: null,
      products: null,
      loading: true
    };
  }

  async componentDidMount() {
    if (!this.state.products) {
      await this.findProducts(this.props.match?.params?.group);
    }
  }

  async componentDidUpdate() {
    if (this.props.match && this.props.match.params && this.props.match.params.group) {
      if (this.state.group !== this.props.match.params.group) {
        await this.findProducts(this.props.match.params.group);
      }
    } else if (this.state.group) {
      await this.findProducts();
    }
  }

  render() {
    return (
      <Container>
        {this.state.loading
          ? this.renderSkeletons()
          : this.state.products?.map(item =>
            <ShopItem
              key={item.id}
              productKey={item.key}
              id={item.id}
              name={item.name}
              sizes={item.sizes}
              theme={this.props.theme}
            />
          )
        }
      </Container>
    );
  }

  renderSkeletons() {
    var skeletons = [];
    for (var i = 0; i < 6; i++) {
      skeletons.push(
        <ItemSkeleton key={i}>
          <ItemImageSkeleton/>
          <ItemTitleSkeleton/>
          <ItemTextSkeleton/>
          <ItemTextSkeleton/>
        </ItemSkeleton>
      );
    }
    return <>{skeletons}</>;
  }

  async findProducts(group) {
    this.setState({
      products: null,
      loading: true,
      group: group
    });
    let products = null;
    const sessionGroup = window.sessionStorage.getItem('group');
    if (sessionGroup === group) {
      products = window.sessionStorage.getItem('products');
    }
    if (!products) {
      products = await api.get('api/products', { 
        group: group !== 'sale' ? group : null,
        sale: group === 'sale'
      });
      window.sessionStorage.setItem('products', JSON.stringify(products));
    } else {
      products = JSON.parse(products);
    }
    this.setState({
      products: products,
      loading: false
    });
  }

}

export default withTheme(ShopPage);