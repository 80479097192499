import React, { Component } from 'react';

export default class NoTumbleDryIcon extends Component {

  render() {
    return (
      <svg width='48' height='48' viewBox='0 0 375 375' className={this.props.className} fill={this.props.color}>
        <path
          fill='none'
          stroke={this.props.color}
          strokeWidth='8'
          d='M 297.02530,187.18597 C 297.03472,124.52065 247.99622,73.715206 187.50092,73.715206 C 127.00562,73.715206 77.967116,124.52065 77.976526,187.18597 C 77.967116,249.85130 127.00562,300.65674 187.50092,300.65674 C 247.99622,300.65674 297.03472,249.85130 297.02530,187.18597 L 297.02530,187.18597 z '
        />
        <rect
          fill='none'
          stroke={this.props.color}
          strokeWidth='8'
          width='247.54399'
          height='247.54399'
          x='63.728004'
          y='63.728004'
        />
        <g transform='translate(-14.62687,-27.33494)'>
          <path
            fill='none'
            stroke={this.props.color}
            strokeWidth='8'
            d='M 55.799488,93.750000 L 318.75000,319.05449'
            transform='matrix(1.147437,0.000000,0.000000,0.873770,-12.88737,34.30970)'
          />
          <path
            fill='none'
            stroke={this.props.color}
            strokeWidth='8'
            d='M 319.20051,93.750000 L 56.250000,319.05449'
            transform='matrix(1.147437,0.000000,0.000000,0.873770,-12.88737,34.30970)'
          />
        </g>
      </svg>
    );
  }

}