import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Drawer, List, ListItem, ListItemText } from '../common';
import { StackLayout, ScrollableLayout } from '../layouts';

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  background-color: ${props => props.theme.gray70};
`;

const NavigationDrawerItemText = styled(ListItemText)`
  color: ${props => props.theme.white};
`;

const NavigationDrawerItem = styled(ListItem).attrs(props => ({
  backgroundColor: props.theme.gray70,
  variant: 'themed'
}))``;

const SelectedIndicator = styled.div`
  position: absolute;
  width: 4px;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: ${props => props.theme.primary40};
`;

export default class NavigationDrawer extends PureComponent {

  constructor(props) {
    super();
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
  }

  render() {
    return (
      <Drawer
        id='navigation-drawer'
        tabIndex='0'
        closed={this.props.closed}
        onClose={this.handleDrawerClose}
      >
        <StackLayout>
          <Content>
            <ScrollableLayout>
              <List>
                {this.props.items?.map(item => (
                  <NavigationDrawerItem
                    key={item.id}
                    id={item.id}
                    onClick={this.handleItemClick}
                  >
                    <NavigationDrawerItemText>{item.title}</NavigationDrawerItemText>
                    {this.props.selectedItemId === item.id &&
                      <SelectedIndicator />
                    }
                  </NavigationDrawerItem>
                ))}
              </List>
            </ScrollableLayout>
          </Content>
        </StackLayout>
      </Drawer>
    );
  }

  handleItemClick(e) {
    const item = this.props.items?.find(item => item.id === e.id);
    if (item) {
      this.handleClose();
      if (this.props.onItemClick) {
        this.props.onItemClick({
          id: e.id,
          path: item.path
        });
      }
    }
  }

  handleDrawerClose() {
    this.handleClose();
  }

  handleCloseButtonClick() {
    this.handleClose();
  }

  handleClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

}