import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Text } from '.';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  background-color: ${props => props.backgroundColor};
  padding: 8px;
  gap: 8px;
`;

const IconContainer = styled.div`
  font-size: 0;
  & > svg {
    fill: ${props => props.textColor};
    height: 16px;
    width: 16px;
  }
`;

export default class MessageBase extends PureComponent {

  render() {
    const Icon = this.props.icon;
    return (
      <Container backgroundColor={this.props.backgroundColor}>
        <IconContainer textColor={this.props.textColor}>
          <Icon />
        </IconContainer>
        <Text
          variant='caption'
          textColor={this.props.textColor}
        >
          {this.props.children}
        </Text>
      </Container>
    );
  }

}