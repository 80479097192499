export default {
  ADD_CART_ITEM: 'AddCartItem',
  DELETE_CART_ITEM: 'DeleteCartItem',
  UPDATE_CART_ITEM_AMOUNT: 'UpdateCartItemAmount',
  FINISH_LOADING_OMNIVA_LOCATIONS: 'FinishLoadingOmnivaLocations',
  SELECT_CART_OMNIVA_LOCATION: 'SelectCartOmnivaLocation',
  SEARCH_CART_OMNIVA_LOCATION: 'SearchCartOmnivaLocation',
  UPDATE_CART_FORM: 'UpdateCartForm',
  SELECT_CART_PAYMENT_TYPE: 'SelectCartPaymentType',
  UPDATE_CART_VALIDATION_RESULT: 'UpdateCartValidationResult',
  LOAD_CART: 'LoadCart',
  FINISH_LOAD_CART_IMAGE: 'FinishLoadCartImage',
  START_LOAD_CART_DELIVERY_PRICE: 'StartLoadCartDeliveryPrice',
  LOAD_CART_DELIVERY_PRICE: 'LoadCartDeliveryPrice',
  CHECK_CART_AGREE_WITH_RULES: 'CheckCartAgreeWithRules'
};