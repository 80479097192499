import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import Text from './text';
import { LockIcon } from '../icons';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 8px;
`;

const DisabledIcon = styled(LockIcon)`
  width: 12px;
  height: 12px;
  padding: 4px 6px 4px 0;
  fill: ${props => props.color || !props.theme.darkMode ? props.theme.gray70 : props.theme.gray50};
`;

const LabelText = styled(Text).attrs(props => ({
  variant: 'subtitle2',
  textColor: props.textColor || !props.theme.darkMode ? props.theme.gray80 : props.theme.white,
  selectionDisabled: true
}))`
  pointer-events: none;
  ${props => props.focused && css`
    color: ${props => props.focusedTextColor || !props.theme.darkMode ? props.theme.neutral50 : props.theme.white};
  `}
  ${props => props.disabled && css`
    color: ${props => props.disabledTextColor || !props.theme.darkMode ? props.theme.gray50 : props.theme.gray30};
  `}
  ${props => props.error && css`
    color: ${props => props.errorTextColor || !props.theme.darkMode ? props.theme.error70 : props.theme.error30};
  `}
`;

const RequiredIndicator = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${props => props.color || !props.theme.darkMode ? props.theme.error70 : props.theme.error30};
  border-radius: 100%;
  margin-left: 2px;
`;

export default class Label extends PureComponent {

  static defaultProps = {
    required: false,
    disabled: false,
    focused: false,
    error: false
  }

  render() {
    return (
      <Container className={this.props.className}>
        {this.props.disabled &&
          <DisabledIcon color={this.props.disabledIconColor} />
        }
        <LabelText
          disabled={this.props.disabled}
          focused={this.props.focused}
          error={this.props.error}
          textColor={this.props.textColor}
          focusedTextColor={this.props.focusedTextColor}
          disabledTextColor={this.props.disabledTextColor}
          errorTextColor={this.props.errorTextColor}
        >
          {this.props.text}
        </LabelText>
        {this.props.required &&
          <RequiredIndicator color={this.props.requiredIndicatorColor} />
        }
      </Container>
    );
  }

}