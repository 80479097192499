import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import configureStore from './store/configureStore';
import { Application } from './features/common';

const history = createBrowserHistory();

ReactDOM.render(
  <Provider store={configureStore(history)}>
    <ConnectedRouter history={history}>
      <Application history={history}/>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('viewport')
);