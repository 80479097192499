import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import styled, { css, withTheme } from 'styled-components';
import parse from 'html-react-parser';
import { Price } from '../../shared';
import { Badge, StackLayout, BaseButton, ToggleButtonGroup, ToggleButton, BasketPlusIcon, respondTo, BoxLayout, Text, Skeleton, Icon } from '../../../ui';
import { addCartItem } from '../../cart';
import api from '../../../utils/api';

const ShopProductImageGallery = React.lazy(() => import(/* webpackChunkName: "image-gallery" */ './shop-product-image-gallery'));

const Container = styled(StackLayout).attrs(props => ({
  orientation: 'horizontal',
  rowGap: '16px',
  columnGap: '16px'
}))`
  margin: 0 8px;
  flex-direction: column;
  ${respondTo('xs', css`
    flex-direction: row;
  `)}
`;

const Gallery = styled(BoxLayout)`
  padding: 0;
  width: 100%;
  ${respondTo('xs', css`
    flex-basis: 50%;
    width: 50%;
  `)}
  position: relative;
`;

const Details = styled(StackLayout).attrs(props => ({
  orientation: 'vertical',
  rowGap: '8px',
  valign: 'top'
}))`
  ${respondTo('xs', css`
    flex-basis: 50%;
  `)}
`;

const DetailsInnerContainer = styled(StackLayout).attrs(props => ({
  orientation: 'vertical',
  rowGap: '8px',
  valign: 'top'
}))`
  flex-grow: 0;
`;

const Title = styled(Text).attrs(props => ({
  variant: 'h6'
}))``;

const Description = styled(Text).attrs(props => ({
  variant: 'body2',
  textColor: props.theme.gray60
}))``;

const Composition = styled(Text).attrs(props => ({
  variant: 'body2',
  textColor: props.theme.gray60
}))``;

const ImageSkeleton = styled(Skeleton).attrs(props => ({
  variant: 'rect',
  width: '100%',
  height: '512px'
}))`

`;

const MaintenanceIcon = styled(Icon).attrs(props => ({
  color: props.theme.gray60
}))`
`;

const PriceContainer = styled(StackLayout).attrs(props => ({
}))`
  margin-top: 32px;
`;

class ShopProductPage extends React.PureComponent {

  constructor(props) {
    super();
    this.state = {
      product: null,
      size: null,
      loading: true,
      images: [],
      imageLoaded: false
    };
    this.getDefaultSize = this.getDefaultSize.bind(this);
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.handleSelectSize = this.handleSelectSize.bind(this);
    this.handleAddCartItem = this.handleAddCartItem.bind(this);
  }

  async componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.key) {
      const key = this.props.match.params.key;
      const product = await api.get(`api/products/${key}`);
      const productImages = await api.get(`api/products/${key}/images`);
      const images = productImages.map(x => ({
        original: `/api/products/${key}/images/${x.id}`,
        thumbnail: `/api/products/${key}/images/${x.id}`
      }));
      this.setState({
        product: product,
        size: product?.sizes?.length > 0 ? this.getDefaultSize(product?.sizes) : null,
        loading: false,
        images: images
      });
    }
  }

  render() {
    return (
      <Container>
        <Gallery>
          <Suspense fallback={<ImageSkeleton/> }>
            {(this.state.loading || !this.state.imageLoaded) && <ImageSkeleton/> }
            {!this.state.loading &&
              <ShopProductImageGallery
                images={this.state.images}
                onImageLoad={this.handleImageLoaded}
                imageLoaded={this.state.imageLoaded}
              />
            }
          </Suspense>
        </Gallery>
        <Details>
          {this.state.loading
            ?
            <DetailsInnerContainer>
              <Skeleton variant='text' height='22px'/>
              <Skeleton variant='text' height='20px'/>
              <Skeleton variant='text' height='24px'/>
              <Skeleton height='36px'/>
            </DetailsInnerContainer>
            :
            <>
              <Title>{this.state.product.title}</Title>
              <Description>{parse(this.state.product.description)}</Description>
              {this.state.product.composition?.length > 0 &&
                <StackLayout orientation='vertical' align='left' rowGap='4px' notGrow>
                  <Text variant='subtitle2'>Sudėtis</Text>
                  <StackLayout orientation='vertical' align='left'>
                    {this.state.product.composition?.map((item, index) =>
                      <StackLayout key={index} orientation='horizontal' align='left' columnGap='4px'>
                        <Composition>{item.percent}%</Composition>
                        <Composition>{item.name}</Composition>
                      </StackLayout>
                    )}
                  </StackLayout>
                </StackLayout>
              }
              {this.state.product.maintenance?.length > 0 &&
                <StackLayout orientation='vertical' align='left' rowGap='4px' notGrow>
                  <Text variant='subtitle2'>Priežiūra</Text>
                  <StackLayout orientation='horizontal' align='left'>
                    {this.state.product.maintenance?.map((item, index) =>
                      <MaintenanceIcon key={index} variant={item.icon} />
                    )}
                  </StackLayout>
                </StackLayout>
              }
              {this.state.size &&
                <PriceContainer orientation='horizontal' align='left' columnGap='4px' notGrow>
                  <Text variant='h6'>Kaina:</Text>
                  <Price
                    price={this.state.size.price}
                    priceWithoutDiscount={this.state.size.priceWithoutDiscount}
                  />
                </PriceContainer>
              }
              <ToggleButtonGroup onChange={this.handleSelectSize}>
                {this.state.product.sizes?.map(item =>
                  <ToggleButton
                    key={item.id}
                    text={item.name}
                    id={item.id}
                    selected={this.state.size?.id === item.id}
                  >
                    {item.priceDiscount > 0 && <Badge color={this.props.theme.error40} value='%' top='-8px' right='-8px' />}
                  </ToggleButton>
                )}
              </ToggleButtonGroup>
              <BaseButton
                text='Į krepšelį'
                leadingIcon={BasketPlusIcon}
                onClick={this.handleAddCartItem}
              />
            </>
          }
        </Details>
      </Container>
    );
  }

  getDefaultSize(sizes) {
    const defaultSize = sizes?.find(item => item.priceDiscount > 0);
    if (!defaultSize) {
      return sizes.length > 0 ? sizes[0] : null;
    } else {
      return defaultSize;
    }
  }

  handleImageLoaded() {
    this.setState({
      imageLoaded: true
    });
  }

  handleAddCartItem() {
    if (this.state.product && this.state.size) {
      this.props.addCartItem({
        size: this.state.size,
        productKey: this.state.product.key,
        productId: this.state.product.id,
        productTitle: this.state.product.title,
        productName: this.state.product.name,
        amount: 1
      });
    }
  }

  handleSelectSize(e) {
    const size = this.state.product?.sizes?.find(x => x.id === e.id);
    this.setState({
      size: size
    });
  }

}

const mapDispatchToProps = dispatch => ({
  addCartItem: (payload) => dispatch(addCartItem(payload))
});

export default connect(null, mapDispatchToProps)(withTheme(ShopProductPage));