import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';

const HorizontalAlignment = {
  Stretch: 'stretch',
  Left: 'left',
  Center: 'center',
  Right: 'right'
};

const VerticalAlignment = {
  Stretch: 'stretch',
  Top: 'top',
  Center: 'center',
  Bottom: 'bottom'
};

const Container = styled.div`
  grid-column: auto;
  ${props => props.column && css`
    grid-column-start: ${props => props.column};
  `}
  ${props => props.columnSpan && css`
    grid-column-end: span ${props => props.columnSpan};
  `}
  ${props => props.row && css`
    grid-row-start: ${props => props.row};
  `}
  ${props => props.rowSpan && css`
    grid-row-end: span ${props => props.rowSpan};
  `}
  display: flex;
  ${props => props.align === HorizontalAlignment.Stretch && css`
    justify-content: flex-start;
    & > * {
      flex-grow: 1;
    }
  `}
  ${props => props.align === HorizontalAlignment.Left && css`
    justify-content: flex-start;
  `}
  ${props => props.align === HorizontalAlignment.Center && css`
    justify-content: center;
  `}
  ${props => props.align === HorizontalAlignment.Right && css`
    justify-content: flex-end;
  `}
  ${props => props.valign === VerticalAlignment.Stretch && css`
    align-items: stretch;
  `}
  ${props => props.valign === VerticalAlignment.Top && css`
    align-items: flex-start;
  `}
  ${props => props.valign === VerticalAlignment.Center && css`
    align-items: center;
  `}
  ${props => props.valign === VerticalAlignment.Bottom && css`
    align-items: flex-end;
  `}
`;

export default class GridItem extends PureComponent {

  static defaultProps = {
    align: HorizontalAlignment.Stretch,
    valign: VerticalAlignment.Stretch
  }

  constructor() {
    super();
  }

  render() {
    return (
      <Container
        className={this.props.className}
        column={this.props.column}
        columnSpan={this.props.columnSpan}
        align={this.props.align}
        valign={this.props.valign}
      >
        {this.props.children}
      </Container>
    );
  }

}