import React, { Component } from 'react';

export default class Iron100Icon extends Component {

  render() {
    return (
      <svg width='48' height='48' viewBox='0 0 375 375' className={this.props.className} fill={this.props.color}>
        <path
          fill='none'
          stroke={this.props.color}
          strokeWidth='8'
          d='M 112.66666,98.261892 L 300.81657,98.261892 L 338.31657,281.21250 L 38.316589,281.21250 C 38.316589,281.21250 94.566585,187.46250 150.81657,187.46250 C 207.06657,187.46250 317.06657,187.46250 317.06657,187.46250'
        />
        <path
          d='M 221.02481,235.00000 C 221.02481,224.64466 213.29062,216.25000 203.75000,216.25000 C 194.20939,216.25000 186.47520,224.64466 186.47520,235.00000 C 186.47520,245.35534 194.20939,253.75000 203.75000,253.75000 C 213.29062,253.75000 221.02481,245.35534 221.02481,235.00000 L 221.02481,235.00000 z '
        />
      </svg>
    );
  }

}