import React, { PureComponent } from 'react';
import * as icons from '../icons';

export default class Icon extends PureComponent {

  render() {
    const IconType = this.getIconType();
    if (IconType) {
      return (
        <IconType
          className={this.props.className}
          variant={this.props.variant}
          color={this.props.color}
        />
      );
    }
    return null;
  }

  getIconType() {
    if (this.props.variant) {
      const iconName = `${this.props.variant}-icon`;
      const fragments = iconName.split('-');
      const iconTypeName = fragments
        .map(x => x.charAt(0).toUpperCase() + x.slice(1))
        .join('');
      return icons[iconTypeName];
    }
    return null;
  }

}