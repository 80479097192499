import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import Text from './text';

const Container = styled.button`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => props.borderColor || props.theme.transparent};
  border-radius: ${props => props.borderRadius || '4px'};
  padding: ${props => props.padding || '0 16px'};
  height: ${props => props.$height || '24px'};
  min-width: ${props => props.minWidth || '80px'};
  color: ${props => props.textColor || props.theme.white};
  fill: ${props => props.textColor || props.theme.white};
  background-color: ${props => props.backgroundColor || props.theme.primary40};
  ${props => props.hovered && css`
    color: ${props => props.hoveredTextColor || props.theme.white};
    fill: ${props => props.hoveredTextColor || props.theme.white};
    background-color: ${props => props.hoveredBackgroundColor || props.theme.primary30};
    border-color: ${props => props.hoveredBorderColor || props.theme.transparent};
  `}
  ${props => props.pressed && css`
    color: ${props => props.pressedTextColor || props.theme.white};
    fill: ${props => props.pressedTextColor || props.theme.white};
    background-color: ${props => props.pressedBackgroundColor || props.theme.primary50};
    border-color: ${props => props.pressedBorderColor || props.theme.transparent};
  `}
  ${props => props.pressing && css`
    transition-duration: 75ms;
    color: ${props => props.pressedTextColor || props.theme.white};
    fill: ${props => props.pressedTextColor || props.theme.white};
    background-color: ${props => props.pressedBackgroundColor || props.theme.primary50};
    border-color: ${props => props.pressedBorderColor || props.theme.transparent};
  `}
  ${props => props.releasing && css`
    transition-duration: 225ms;
  `}
  ${props => props.focused && css`
    padding: ${props => props.focusedPadding || '0 15px'};
    ${props.focusedPadding !== '0' && css`
      border: solid 2px ${props => props.focusedBorderColor || props.theme.primary50};
    `}
    ${props.padding === '0' && css`
      outline-offset: 2px;
      outline: solid 2px ${props => props.focusedBorderColor || props.theme.primary50};
    `}
  `}
  ${props => props.disabled && css`
    cursor: default;
    color: ${props => props.disabledTextColor || props.theme.gray50};
    fill: ${props => props.disabledTextColor || props.theme.gray50};
    background-color: ${props => props.disabledBackgroundColor || props.theme.gray20};
    border-color: ${props => props.disabledBorderColor || props.theme.transparent};
  `}
  ${props => props.$loading && css`
    cursor: default;
  `}
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
`;

const Caption = styled(Text).attrs(props => ({
  variant: props.textVariant || 'button1',
  truncate: true,
  selectionDisabled: true
}))`
  color: inherit;
  ${props => props.$loading && css`
    visibility: hidden;
  `}
`;

const TrailingIconContainer = styled.div`
  font-size: 0;
  &:nth-child(2) {
    margin: ${props => props.margin || '0 0 0 8px'};
  }
  ${props => props.$loading && css`
    visibility: hidden;
  `}
  ${props => props.iconWidth && css`
    & > svg {
      width: ${props => props.iconWidth};
    }
  `}
  ${props => props.iconHeight && css`
    & > svg {
      height: ${props => props.iconHeight};
    }
  `}
`;

const LeadingIconContainer = styled.div`
  font-size: 0;
  margin: ${props => props.margin || '0 8px 0 0'};
  ${props => props.$loading && css`
    visibility: hidden;
  `}
  ${props => props.iconWidth && css`
    & > svg {
      width: ${props => props.iconWidth};
    }
  `}
  ${props => props.iconHeight && css`
    & > svg {
      height: ${props => props.iconHeight};
    }
  `}
`;

class BaseButton extends PureComponent {

  static defaultProps = {
    type: 'button'
  };

  constructor(props) {
    super();
    this.state = {
      hovered: false,
      focused: false,
      pressed: false,
      pressing: false,
      releasing: false
    };
    this.ref = props.forwardedRef || React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.handlePointerEnter = this.handlePointerEnter.bind(this);
    this.handlePointerLeave = this.handlePointerLeave.bind(this);
    this.handlePointerDown = this.handlePointerDown.bind(this);
    this.handlePointerUp = this.handlePointerUp.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTransitionEnd = this.handleTransitionEnd.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  render() {
    const TrailingIcon = this.props.trailingIcon;
    const LeadingIcon = this.props.leadingIcon;
    return (
      <Container
        ref={this.ref}
        type={this.props.type}
        form={this.props.form}
        tabIndex={this.props.loading ? '-1' : this.props.tabIndex || '0'}
        {...this.state}
        className={this.props.className}
        disabled={this.props.disabled}
        $loading={this.props.loading}
        color={this.props.color}
        $height={this.props.height}
        minWidth={this.props.minWidth}
        textColor={this.props.textColor}
        backgroundColor={this.props.backgroundColor}
        borderColor={this.props.borderColor}
        padding={this.props.padding}
        hoveredTextColor={this.props.hoveredTextColor}
        hoveredBackgroundColor={this.props.hoveredBackgroundColor}
        hoveredBorderColor={this.props.hoveredBorderColor}
        pressedTextColor={this.props.pressedTextColor}
        pressedBackgroundColor={this.props.pressedBackgroundColor}
        pressedBorderColor={this.props.pressedBorderColor}
        focusedBorderColor={this.props.focusedBorderColor}
        focusedPadding={this.props.focusedPadding}
        disabledTextColor={this.props.disabledTextColor}
        disabledBackgroundColor={this.props.disabledBackgroundColor}
        disabledBorderColor={this.props.disabledBorderColor}
        borderRadius={this.props.borderRadius}
        onClick={this.handleClick}
        onPointerEnter={this.handlePointerEnter}
        onPointerLeave={this.handlePointerLeave}
        onPointerDown={this.handlePointerDown}
        onPointerUp={this.handlePointerUp}
        onTouchMove={this.handleTouchMove}
        onTransitionEnd={this.handleTransitionEnd}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      >
        {this.props.leadingIcon &&
          <LeadingIconContainer
            margin={this.props.leadingIconMargin}
            iconWidth={this.props.iconWidth}
            iconHeight={this.props.iconHeight}
            $loading={this.props.loading}
          >
            <LeadingIcon />
          </LeadingIconContainer>
        }
        {this.props.text &&
          <Caption $loading={this.props.loading} textVariant={this.props.textVariant}>{this.props.text}</Caption>
        }
        {this.props.trailingIcon &&
          <TrailingIconContainer
            margin={this.props.trailingIconMargin}
            iconWidth={this.props.iconWidth}
            iconHeight={this.props.iconHeight}
            $loading={this.props.loading}
          >
            <TrailingIcon />
          </TrailingIconContainer>
        }
        {this.decorate(this.props.children)}
      </Container>
    );
  }

  decorate(children) {
    return React.Children.map(children, (child, index) => {
      if (child && child.type) {
        return (
          <child.type
            key={index}
            loading={this.props.loading}
            hovered={this.state.hovered}
            focused={this.state.focused}
            pressed={this.state.pressed}
            pressing={this.state.pressing}
            releasing={this.state.releasing}
            {...child.props}
          >
            {child.props.children}
          </child.type>
        );
      }
      return null;
    });
  }

  handleClick(e) {
    e.stopPropagation();
    if (!this.props.loading) {
      if (this.props.onClick) {
        e.preventDefault();
        this.props.onClick({
          id: this.props.id,
          name: this.props.name
        });
      }
    } else {
      e.preventDefault();
    }
  }

  handlePointerEnter(e) {
    if (!this.props.loading && e.pointerType === 'mouse') {
      this.setState({
        hovered: true
      });
    }
  }

  handlePointerLeave(e) {
    if (!this.props.loading && e.pointerType === 'mouse') {
      this.setState({
        hovered: false,
        pressed: false,
        pressing: false,
        releasing: false
      });
    }
  }

  handlePointerDown(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.props.loading) {
      this.setState({
        pressed: true,
        pressing: true,
        releasing: false
      });
    }
  }

  handlePointerUp(e) {
    e.stopPropagation();
    if (!this.props.loading) {
      this.setState(state => {
        if (state.pressing) {
          return {
            pressed: false
          };
        } else {
          return {
            pressed: false,
            releasing: true
          };
        }
      });
    }
  }

  handleTouchMove() {
    if (!this.props.loading) {
      this.setState({
        pressed: false,
        pressing: false,
        releasing: false
      });
    }
  }

  handleTransitionEnd(e) {
    e.stopPropagation();
    if (e.target === this.ref.current && (e.propertyName === 'background-color' || e.propertyName === 'color')) {
      this.setState(state => {
        if (state.pressing && !state.pressed) {
          return {
            pressing: false,
            releasing: true
          };
        } else if (state.pressing) {
          return {
            pressing: false
          };
        } else if (state.releasing) {
          return {
            releasing: false
          };
        }
        return null;
      });
    }
  }

  handleFocus(e) {
    e.preventDefault();
    this.setState({
      focused: true
    }, () => {
      if (this.props.onFocus) {
        this.props.onFocus({
          id: this.props.id
        });
      }
    });
  }

  handleBlur(e) {
    e.preventDefault();
    this.setState({
      focused: false
    }, () => {
      if (this.props.onBlur) {
        this.props.onBlur({
          id: this.props.id
        });
      }
    });
  }

}

export default React.forwardRef((props, ref) => <BaseButton forwardedRef={ref} {...props} />);