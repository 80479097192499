import React, { PureComponent, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { ShopPage, ShopProductPage } from '../../shop';
import { Header, Footer } from '../../site';
import { PaymentAcceptPage, PaymentCancelPage } from '../../payment';
import { OrderTrackingPage } from '../../order-tracking';
import { Theme, Text, elevation, BoxLayout, Skeleton } from '../../../ui';
import { ErrorPage, MenuItems } from '../';
import { dialogClosed, closeDialog, loadCartFromLocalStorage } from '../actions';

const CartPage = lazy(() => import(/* webpackChunkName: "cart" */ '../../cart/components/cart-page'));
const ReturnPolicyPage = lazy(() => import(/* webpackChunkName: " return-policy" */ '../../site/components/return-policy-page'));
const DeliveryPage = lazy(() => import(/* webpackChunkName: "delivery" */ '../../site/components/delivery-page'));
const RulesPage = lazy(() => import(/* webpackChunkName: "rules" */ '../../site/components/rules-page'));

const Body = styled.div`
  min-width: 304px;
  max-width: 1024px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 16px 0px;
`;

const Commercial = styled(BoxLayout)`
  background-color: #cc2437;
  padding: 4px;
  text-align: center;
  margin-bottom: 4px;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
  z-index: 10;
  ${elevation(1)}
`;

const PageLoader = styled(Skeleton)`
  height: calc(100vh - 438px);
`;

const CommercialText = styled(Text).attrs(props => ({
  variant: 'subtitle1',
  textColor: props.theme.white,
  selectionDisabled: true
}))``;

class Application extends PureComponent {

  constructor(props) {
    super();
    this.state = {
      drawerClosed: true,
      selectedMenuItemId: null
    };
    this.handleDialogAnimationEnd = this.handleDialogAnimationEnd.bind(this);
    this.handleUnhandledError = this.handleUnhandledError.bind(this);
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleSelectMenuItem = this.handleSelectMenuItem.bind(this);
    window.sessionStorage.clear();
  }

  componentDidMount() {
    window.addEventListener('error', this.handleUnhandledError);
    window.addEventListener('unhandledrejection', this.handleUnhandledError);
    let selectedMenuItemId = null;
    let path = window.location.pathname;
    MenuItems.forEach((item) => {
      if (item.path === path) {
        selectedMenuItemId = item.id;
      }
    });
    this.setState({
      selectedMenuItemId: selectedMenuItemId
    });
    this.props.loadCartFromLocalStorage();
  }

  componentWillUnmount() {
    window.removeEventListener('error', this.handleUnhandledError);
    window.removeEventListener('unhandledrejection', this.handleUnhandledError);
  }

  render() {
    let cartCount = 0;
    this.props.cart?.map(item => { cartCount = cartCount + item.amount; });
    return (
      <ThemeProvider theme={Theme}>
        <Header
          history={this.props.history}
          drawerClosed={this.state.drawerClosed}
          selectedMenuItemId={this.state.selectedMenuItemId}
          cartCount={cartCount}
          onNavidationItemSelect={this.handleSelectMenuItem}
          onDrawerOpen={this.handleDrawerOpen}
          onDrawerClose={this.handleDrawerClose}
          items={MenuItems}
        />
        <Body>
          <Suspense fallback={<PageLoader/>}>
            <Switch>
              <Route exact path='/'component={ShopPage} />
              <Route exact path='/shop' component={ShopPage} />
              <Route exact path='/shop/:group' component={ShopPage} />
              <Route exact path='/product/:key' component={ShopProductPage} />
              <Route exact path='/cart' component={CartPage} />
              <Route exact path='/payment/accept' component={PaymentAcceptPage} />
              <Route exact path='/payment/cancel' component={PaymentCancelPage} />
              <Route exact path='/order_tracking/:key' component={OrderTrackingPage} />
              <Route exact path='/error' component={ErrorPage} />
              <Route exact path='/return_policy' component={ReturnPolicyPage} />
              <Route exact path='/delivery' component={DeliveryPage} />
              <Route exact path='/rules' component={RulesPage} />
            </Switch>
          </Suspense>
        </Body>
        <Footer history={this.props.history}/>
        {this.props.activeDialog &&
          <this.props.activeDialog.type
            {...this.props.activeDialog.props}
            history={this.props.history}
            closed={this.props.activeDialog.closed}
            onDialogClose={this.props.closeDialog}
            onAnimationEnd={() => this.handleDialogAnimationEnd(this.props.activeDialog)}
          />
        }
      </ThemeProvider>
    );
  }

  handleDrawerClose() {
    this.setState({
      drawerClosed: true
    });
  }

  handleDrawerOpen() {
    this.setState({
      drawerClosed: false
    });
  }

  handleSelectMenuItem(payload) {
    this.setState({
      selectedMenuItemId: payload.id
    });
    this.props.history.push(payload.path);
  }

  handleDialogAnimationEnd(dialog) {
    if (dialog.closed) {
      this.props.dialogClosed();
    }
  }

  handleUnhandledError(e) {
    this.props.history.push('/error');
  }
}

const mapStateToProps = state => ({
  activeDialog: state.application.activeDialog,
  cart: state.cart.items
});

const mapDispatchToProps = dispatch => ({
  closeDialog: (payload) => dispatch(closeDialog(payload)),
  dialogClosed: (payload) => dispatch(dialogClosed(payload)),
  loadCartFromLocalStorage: (payload) => dispatch(loadCartFromLocalStorage(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Application);
