import axios from 'axios';
import uuid from './uuid';

axios.defaults.timeout = 30000;
axios.defaults.headers.common = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

const updateDefaultHeaders = headers => {
  axios.defaults.headers.common = {
    ...axios.defaults.headers.common,
    ...headers
  };
};

const get = async (url, parameters = {}, options = {}) => {
  return await performRequest({
    ...options,
    method: 'get',
    baseURL: '/',
    url,
    params: parameters
  });
};

const post = async(url, data, options = {}) => {
  return performRequest({
    ...options,
    method: 'post',
    url,
    data: convertToSnakeCase(data),
    ignoreCancellation: true
  });
};

const put = (url, data, options = {}) => {
  return performRequest({
    ...options,
    method: 'put',
    url,
    data,
    ignoreCancellation: true
  });
};

const performDelete = (url, data, options = {}) => {
  return performRequest({
    ...options,
    method: 'delete',
    url,
    data,
    ignoreCancellation: true
  });
};

const performRequest = async (options) => {
  try {
    let id = window.localStorage.getItem('id');
    if (!id) {
      id = uuid();
      window.localStorage.setItem('id', id);
    }
    const opt = { ...options, headers: { ...options?.headers, 'x-trace-id': id } };
    const response = await axios.request(opt);
    if (response.status === 200 || response.status === 201) {
      return convertToPascalCase(response.data);
    } if (response.status === 204) {
      return null;
    } else {
      throw response;
    }
  } catch (e) {
    if (axios.isCancel(e)) {
      return e;
    }
    throw e.response;
  }
};

const convertToPascalCase = (value) => {
  let result = null;
  if (value instanceof Array) {
    result = [];
    for (let item of value) {
      result.push(convertToPascalCase(item));
    }
  } else if (value instanceof Object) {
    result = {};
    for (let propertyName in value) {
      result[toPascalCase(propertyName)] = convertToPascalCase(value[propertyName]);
    }
  } else {
    result = value;
  }
  return result;
};

const convertToSnakeCase = (value) => {
  let result = null;
  if (value instanceof Array) {
    result = [];
    for (let item of value) {
      result.push(convertToSnakeCase(item));
    }
  } else if (value instanceof Object) {
    result = {};
    for (let propertyName in value) {
      result[toSnakeCase(propertyName)] = convertToSnakeCase(value[propertyName]);
    }
  } else {
    result = value;
  }
  return result;
};

const toPascalCase = (propertyName) => {
  return propertyName.replace(/(_[a-z])/g, function (param) {
    return param.toUpperCase().replace('_', '');
  });
};

const toSnakeCase = (propertyName) => {
  return propertyName.replace(/([A-Z])/g, function (param) {
    return '_' + param.toLowerCase();
  });
};

export default {
  get,
  post,
  put,
  delete: performDelete,
  updateDefaultHeaders,
  convertToPascalCase,
  convertToSnakeCase,
  toPascalCase,
  toSnakeCase
};
