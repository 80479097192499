import React from 'react';
import styled from 'styled-components';
import { StackLayout, Text, Skeleton } from '../../../ui';
import api from '../../../utils/api';

const Container = styled(StackLayout).attrs(props => ({
  orientation: 'vertical',
  rowGap: '16px'
}))`
  margin: 8px;
`;

export default class OrderTrackingPage extends React.PureComponent {

  constructor() {
    super();
    this.state = {
      loading: true,
      order: null
    };
  }

  async componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.key) {
      const order = await api.get(`api/orders/${this.props.match.params.key}`);
      this.setState({
        loading: false,
        order: order
      });
    }
  }

  render() {
    return (
      <Container>
        {this.state.loading
          ?
          <>
            <Skeleton variant='text' height='26px'/>
            <Skeleton variant='text' height='21px'/>
          </>
          :
          <>
            <StackLayout orientation='horizontal' align='left' columnGap='8px'>
              <Text variant='h4'>Užsakymo būsena</Text>
            </StackLayout>
            <Text variant='body1'>Užsakymas nr. {this.state.order?.number ? this.state.order?.number : '-'} šiuo metu yra {this.renderStatusText(this.state.order?.status)}</Text>
          </>
        }
      </Container>
    );
  }

  renderStatusText(status) {
    if (status === 'Created') {
      return 'sukurtas ir laukiama apmokėjimo patvirtinimo.';
    } else if (status === 'Paid') {
      return 'apmokėtas ir bus pradėtas pakuoti';
    } else if (status === 'Packing') {
      return 'pradėtas pakuoti ir bus kuo skubiau išsiūstas';
    } else if (status === 'Send') {
      return 'supakuotas ir išsiūstas';
    } else if (status === 'Delivered') {
      return 'pristatytas';
    } else if (status === 'Canceled') {
      return 'atšauktas';
    }
    return '-';
  }
}