import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { respondTo, StackLayout } from '../../../ui';

const Nav = styled(StackLayout).attrs(props => ({
  orientation: 'horizontal',
  columnGap: '16px',
  align: 'right'
}))`
  display: none;
  ${respondTo('xs', css`
    display: flex;
  `)}
`;

const MenuItem = styled.div`
  cursor: pointer;
  position: relative;
  vertical-align: middle;
  display: inline-block;
  padding: 0;
  color: #234828;

  &:hover {
    color: #234828;
  }

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 1px;
    bottom: -3px;
    left: 0;
    background-color: #234828;
    transition: all 0.2s ease-in-out;
    z-index: 1
  }

  &:hover:before {
    visibility: visible;
    width: 100%;
  }

  & a {
    font-size: 20px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
    text-decoration: none;
    color: inherit;
  }

  & a:hover {
    text-decoration: none;
  }
`;

class Menu extends React.PureComponent {

  render() {
    return (
      <Nav>    
         <MenuItem>
          <NavLink to='/shop/berniukams' >
            Mergaitėms
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to='/shop/mergaitems' >
            Berniukams
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to='/shop/sale' >
            Išpardavimas
          </NavLink>
        </MenuItem>
      </Nav>
    );
  }

}

export default Menu;