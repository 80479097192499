import Validator from './validator';

export default class RequiredValidator extends Validator {

  constructor({ propertyName, errorMessage } = {}) {
    super();
    this.propertyName = propertyName;
    if (!this.propertyName) {
      throw new Error('Property name is not set for required validator');
    }
    this.errorMessage = errorMessage || 'Privalomas laukas';
  }

  validate(validationContext) {
    return this.validateProperty(validationContext.target, this.propertyName, x => {
      if (x.value !== null && x.value !== undefined && x.value !== '') {
        return true;
      }
      validationContext.addError(
        x.path,
        this.errorMessage
      );
      return false;
    });
  }

}