import React, { PureComponent } from 'react';
import styled, { keyframes, css } from 'styled-components';

const shine = keyframes`
  from {
    left: -160px;
  }
  to {
    left: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  background-color: ${props => props.backgroundColor || props.theme.gray10};
  position: relative;
  overflow: hidden;
  ::before {
    content: '';
    display: block;
    position: absolute;
    left: -160px;
    top: 0;
    height: 100%;
    width: 160px;
    background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, ${props => props.animationOpacity}) 50%, transparent 100%);
    animation: ${shine} 2s ease-in-out infinite;
  }
`;

const Rectangle = styled(Container)`
  height: ${props => props.$height ? props.$height : '40px'};
  width: auto;
  ${props => props.$width && css`
    width: ${props.$width};
    flex-grow: 0;
  `}
`;

const Circle = styled(Container)`
  height: ${props => props.$height ? props.$height : '40px'};
  width: ${props => props.$width ? props.$width : '40px'};
  border-radius: 50%;
`;

const Text = styled(Container)`
  height: ${props => props.$height ? props.$height : '16px'};
  width: auto;
  ${props => props.$width && css`
    width: ${props.$width};
    flex-grow: 0;
  `}
  margin: 2px 0;
  border-radius: 4px;
`;

class Skeleton extends PureComponent {

  static defaultProps = {
    variant: 'rect',
    animationOpacity: 0.4
  }

  render() {
    switch (this.props.variant) {
      case 'rect':
        return (
          <Rectangle
            ref={this.props.forwardedRef}
            className={this.props.className}
            $width={this.props.width}
            $height={this.props.height}
            backgroundColor={this.props.backgroundColor}
            animationOpacity={this.props.animationOpacity}
          />
        );
      case 'circle':
        return (
          <Circle
            ref={this.props.forwardedRef}
            className={this.props.className}
            $width={this.props.width}
            $height={this.props.height}
            backgroundColor={this.props.backgroundColor}
            animationOpacity={this.props.animationOpacity}
          />
        );
      case 'text':
        return (
          <Text
            ref={this.props.forwardedRef}
            className={this.props.className}
            $width={this.props.width}
            $height={this.props.height}
            backgroundColor={this.props.backgroundColor}
            animationOpacity={this.props.animationOpacity}
          />
        );
    }
  }

}

export default React.forwardRef((props, ref) => <Skeleton {...props} forwardedRef={ref} />);