import React, { PureComponent } from 'react';
import styled from 'styled-components';
import BaseButton from './base-button';

const Container = styled(BaseButton).attrs(props => ({
  textColor: props.textColor || (props.selected ? props.theme.white : props.theme.neutral50),
  backgroundColor: props.backgroundColor || (props.selected ? props.theme.neutral40 : props.theme.white),
  borderColor: props.borderColor || (props.selected ? props.theme.neutral40 : props.theme.neutral20),
  hoveredTextColor: props.hoveredTextColor || (props.selected ? props.theme.white : props.theme.neutral50),
  hoveredBackgroundColor: props.hoveredBackgroundColor || (props.selected ? props.theme.neutral30 : props.theme.neutral10),
  hoveredBorderColor: props.hoveredBorderColor || (props.selected ? props.theme.neutral30 : props.theme.neutral20),
  pressedTextColor: props.pressedTextColor || (props.selected ? props.theme.white : props.theme.neutral70),
  pressedBackgroundColor: props.pressedBackgroundColor || (props.selected ? props.theme.neutral50 : props.theme.neutral20),
  pressedBorderColor: props.pressedBorderColor || (props.selected ? props.theme.neutral50 : props.theme.neutral30),
  focusedBorderColor: props.focusedBorderColor || props.theme.neutral50,
  progressIndicatorColor: props.progressIndicatorColor || (props.selected ? props.theme.white : props.theme.neutral50),
  textVariant: 'button2',
  padding: '0 8px',
  focusedPadding: '0 7px',
  height: '24px',
  iconWidth: '16px',
  iconHeight: '16px',
  trailingIconMargin: '0 -4px 0 4px',
  leadingIconMargin: '0 4px 0 -4px',
  minWidth: '0'
}))``;

export default class ToggleButton extends PureComponent {

  render() {
    const { ...props } = this.props;
    return (
      <Container {...props}>
        {this.props.children}
      </Container>
    );
  }

}