import ValidationContext from './validation-context';
import ValidationResult from './validation-result';

export default class Validator {

  static validateObject(target, validators) {
    if (!target) {
      throw new Error('Target is null');
    }
    if (!validators) {
      throw new Error('Validators is null');
    }
    const validationContext = new ValidationContext({
      target: target
    });
    let valid = true;
    if (!Array.isArray(validators)) {
      validators = [ validators ];
    }
    for (let validator of validators) {
      if (validator instanceof Validator) {
        if (!validator.validate(validationContext)) {
          valid = false;
        }
      } else if (typeof validator === 'function') {
        if (!validator(validationContext)) {
          valid = false;
        }
      }
    }
    return new ValidationResult({
      valid: valid,
      errorMessage: validationContext.errorMessage,
      errors: validationContext.errors
    });
  }

  hasValue(value) {
    return typeof value !== 'undefined' && value !== null;
  }

  eval(target, path) {
    let result = [];
    if (target) {
      const fragments = path.split('.');
      if (fragments.length === 1) {
        result.push({
          path: path,
          value: target[path]
        });
      } else {
        let propertyName = fragments.shift();
        if (!propertyName.includes('[]')) {
          const items = this.eval(target[propertyName], fragments.join('.'));
          for (let item of items) {
            item.path = `${propertyName}.${item.path}`;
          }
          result = result.concat(items);
        } else {
          propertyName = propertyName.replace('[]', '');
          if (target[propertyName]) {
            for (let i = 0; i < target[propertyName].length; i++) {
              const items = this.eval(target[propertyName][i], fragments.join('.'));
              for (let item of items) {
                item.path = `${propertyName}[${i}].${item.path}`;
              }
              result = result.concat(items);
            }
          }
        }
      }
    }
    return result;
  }

  validateProperty(obj, propertyName, func) {
    let valid = true;
    const result = this.eval(obj, propertyName);
    for (let item of result) {
      if (!func(item)) {
        valid = false;
      }
    }
    return valid;
  }

  validate(validationContext) {
    return true;
  }
}