import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Text } from '../../../ui';
import ErrorPageImage from './error-page-image';

const Header = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const MessageContainer = styled.div`
  box-sizing: border-box;
  height: 120px;
  max-width: 320px;
  padding: 0 16px 0 16px;
`;

const ErrorText = styled(Text).attrs(props => ({
  variant: 'h1',
  textColor: props.theme.primary30,
  selectionDisabled: true
}))``;

const Footer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const FooterInnerContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  padding: 16px;
`;

const FooterText = styled(Text).attrs(props => ({
  variant: 'body2',
  textColor: props.theme.gray60,
  selectionDisabled: true
}))`
  display: block;
  max-width: 440px;
`;

const ErrorImage = styled(ErrorPageImage).attrs(props => ({
  color: props.theme.gray60
}))``;

export default class ErrorPage extends PureComponent {

  render() {
    return (
      <div>
        <Header>
          <ImageContainer>
            <ErrorImage />
          </ImageContainer>
          <MessageContainer>
            <ErrorText>Įvyko techninė klaida</ErrorText>
          </MessageContainer>
        </Header>
        <Footer>
          <FooterInnerContainer>
            <FooterText>
              Atsiprašome, bet negalime parodyti jūsų norimo puslapio. Jei tai kartosis prašome pranėšti info@eppe.lt. Ačiū.
            </FooterText>
          </FooterInnerContainer>
        </Footer>
      </div>
    );
  }

}

