import ApplicationActionTypes from './action-types';
import CartActionTypes from '../cart/action-types';
import uuid from '../../utils/uuid';

export const openDialog = (payload) => async (dispatch) => {
  const id = uuid();
  const activeDialog = {
    id: id,
    type: payload.type,
    props: payload.props
  };
  dispatch({
    type: ApplicationActionTypes.OPEN_DIALOG,
    activeDialog: activeDialog
  });
};

export const closeDialog = () => async (dispatch) => {
  dispatch({
    type: ApplicationActionTypes.CLOSE_DIALOG
  });
};

export const dialogClosed = () => async (dispatch) => {
  dispatch({
    type: ApplicationActionTypes.DIALOG_CLOSED
  });
};

export const loadCartFromLocalStorage = () => async (dispatch) => {
  try {
    const cart = JSON.parse(window.localStorage.getItem('cart'));
    if (cart) {
      dispatch({
        type: CartActionTypes.LOAD_CART,
        cart: cart
      });
    }
  } catch {
    window.localStorage.clear('cart');
  }
};