import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { MessageBase } from '.';
import { ErrorIcon } from '../icons';

const Container = styled(MessageBase).attrs(props => ({
  icon: ErrorIcon,
  textColor: props.theme.error70,
  backgroundColor: props.theme.error10
}))``;

export default class ErrorMessage extends PureComponent {

  render() {
    return (
      <Container>{this.props.children}</Container>
    );
  }

}