import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { withKeyboardEvents } from '../shared';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: ${props => props.backgroundColor || props.theme.white};
  :focus {
    outline: none;
  }
`;

class List extends PureComponent {

  static defaultProps = {
    autofocus: true
  }

  constructor() {
    super();
    this.containerRef = React.createRef();
    this.handleItemClick = this.handleItemClick.bind(this);
  }

  render() {
    return (
      <Container
        ref={this.containerRef}
        tabIndex={this.props.tabIndex}
        onKeyDown={this.props.onKeyDown}
        onBlur={this.props.onBlur}
        className={this.props.className}
        backgroundColor={this.props.backgroundColor}
      >
        {this.decorate(this.props.children)}
      </Container>
    );
  }

  handleItemClick(e) {
    if (this.props.autofocus) {
      this.containerRef.current.focus();
    }
    if (this.props.onItemClick) {
      this.props.onItemClick({
        id: e.id
      });
    }
  }

  focus() {
    this.containerRef.current.focus();
  }

  blur() {
    this.containerRef.current.blur();
  }

  decorate(children) {
    children = this.flatten(children);
    return React.Children.map(children, (child) => {
      if (child.props.nested) {
        return (
          <child.type {...child.props}>
            {this.decorate(child.props.children)}
          </child.type>
        );
      } else {
        return (
          <child.type
            {...child.props}
            onClick={e => {
              this.handleItemClick(e);
              if (child.props.onClick) {
                child.props.onClick(e);
              }
            }}
            ref={value => {
              this.setRef(child.ref, value);
            }}
          >
            {child.props.children}
          </child.type>
        );
      }
    });
  }

  flatten(children) {
    let result = [];
    React.Children.forEach(children, child => {
      if (child) {
        if (child.type === React.Fragment) {
          result = result.concat(this.flatten(child.props.children));
        } else {
          result.push(child);
        }
      }
    });
    return result;
  }

  setRef(ref, value) {
    if (ref) {
      if (typeof ref === 'function') {
        ref(value);
      } else {
        ref.current = value;
      }
    }
  }

}

export default withKeyboardEvents(List);