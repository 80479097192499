import React from 'react';

export default class ErrorBoundary extends React.PureComponent {

  componentDidCatch(error, errorInfo) {
    if (this.props.onError) {
      this.props.onError({
        error: error,
        errorInfo: errorInfo
      });
    }
  }

  render() {
    return this.props.children;
  }

}