import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { elevation, KeyCodes, withoutEventPropagation } from '../shared';

const Margin = 4;

const Position = {
  top: 'top',
  bottom: 'bottom'
};

const Alignment = {
  left: 'left',
  center: 'center',
  right: 'right',
  stretch: 'stretch'
};

const Content = styled.div.attrs(props => ({
  style: {
    top: `${props.top}px`,
    left: `${props.left}px`,
    width: props.width > 0 ? `${props.width}px` : null,
    visibility: props.visible ? 'visible' : 'hidden'
  }
}))`
  position: fixed;
  z-index: 100;
  border-radius: 4px;
  overflow: hidden;
  ${props => props.position === Position.bottom && css`
    margin-top: ${Margin}px;
  `}
  ${props => props.position === Position.top && css`
    margin-top: -${Margin}px;
  `}
  color: initial;
  background: white;
  ${elevation(2)}
`;

class Popover extends PureComponent {

  static defaultProps = {
    position: Position.bottom,
    alignment: Alignment.center
  }

  constructor(props) {
    super();
    this.state = {
      top: null,
      left: null,
      width: null,
      visible: props.open,
      open: props.open,
      opening: props.open,
      calculatedPosition: props.position
    };
    this.contentRef = props.forwardedRef || React.createRef();
    this.invalidateRequest = null;
    this.invalidate = this.invalidate.bind(this);
    this.handlePointerDown = this.handlePointerDown.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  static getDerivedStateFromProps(nextProps, currentState) {
    if ('open' in nextProps && nextProps.open !== currentState.open) {
      return {
        open: nextProps.open,
        opening: nextProps.open === true
      };
    }
    return null;
  }

  componentDidMount() {
    document.addEventListener('pointerdown', this.handlePointerDown, true);
    document.addEventListener('keydown', this.handleKeyDown);
    this.invalidateRequest = requestAnimationFrame(this.invalidate);
  }

  componentDidUpdate() {
    if (this.state.open && !this.invalidateRequest) {
      this.invalidateRequest = requestAnimationFrame(this.invalidate);
    } else if (!this.state.open && this.invalidateRequest) {
      cancelAnimationFrame(this.invalidateRequest);
      this.invalidateRequest = null;
    }
  }

  componentWillUnmount() {
    document.removeEventListener('pointerdown', this.handlePointerDown, true);
    document.removeEventListener('keydown', this.handleKeyDown);
    if (this.invalidateRequest) {
      cancelAnimationFrame(this.invalidateRequest);
      this.invalidateRequest = null;
    }
  }

  invalidate() {
    const { top, left, width, position, visible } = this.calculatePosition();
    this.setState({
      top: top,
      left: left,
      width: this.props.alignment === Alignment.stretch && width || null,
      visible: visible && !this.state.opening,
      calculatedPosition: position
    }, () => {
      if (this.state.opening) {
        this.setState({
          opening: false
        }, () => {
          this.invalidateRequest = this.state.open ? requestAnimationFrame(this.invalidate) : null;
        });
      } else {
        this.invalidateRequest = this.state.open ? requestAnimationFrame(this.invalidate) : null;
      }
    });
  }

  render() {
    if (this.state.open && this.props.target) {
      const { top, left, width, visible, calculatedPosition } = this.state;
      return (
        <Content
          ref={this.contentRef}
          top={top}
          left={left}
          width={width}
          position={calculatedPosition}
          visible={visible}
        >
          {this.props.children}
        </Content>
      );
    }
    return null;
  }

  calculatePosition() {
    const targetClientRect = this.props.target && this.props.target.getBoundingClientRect();
    const popoverClientRect = this.contentRef.current && this.contentRef.current.getBoundingClientRect();
    const preferredPosition = this.props.position;
    let result = {
      top: 0,
      left: 0,
      width: targetClientRect && targetClientRect.width,
      position: preferredPosition,
      visible: true
    };
    if (targetClientRect && popoverClientRect && this.state.open) {
      const { alignment } = this.props;
      if (alignment === Alignment.center || alignment === Alignment.stretch) {
        result.left = (targetClientRect.width / 2) - (popoverClientRect.width / 2) + targetClientRect.left;
      } else if (alignment === Alignment.left) {
        result.left = targetClientRect.left;
      } else if (alignment === Alignment.right) {
        result.left = targetClientRect.left - popoverClientRect.width + targetClientRect.width;
      }
      let calculatedPosition = preferredPosition;
      if (calculatedPosition === Position.top) {
        result.top = -popoverClientRect.height + targetClientRect.top;
      } else if (calculatedPosition === Position.bottom) {
        result.top = targetClientRect.height + targetClientRect.top;
      }
      result.position = calculatedPosition;
    }
    return result;
  }

  handlePointerDown(e) {
    if (this.props.onClose) {
      const pointerOutside = !this.contentRef.current?.contains(e.target);
      const pointerOnTarget = this.props.target?.contains(e.target);
      if (pointerOutside && !pointerOnTarget) {
        this.props.onClose();
      }
    }
  }

  handleKeyDown(e) {
    if (e.key === KeyCodes.ESCAPE && this.props.onClose) {
      this.props.onClose();
    }
  }

}

export default withoutEventPropagation(React.forwardRef((props, ref) => <Popover forwardedRef={ref} {...props} />));