import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  min-height: 48px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  ${props => !props.disabled && css`
    cursor: pointer;
  `}
  transition-property: background-color, color, fill;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  fill: ${props => props.textColor};
  ${props => props.activated && css`
    color: ${props => props.activatedTextColor};
    fill: ${props => props.activatedTextColor};
    background-color: ${props => props.activatedBackgroundColor};
  `}
  ${props => props.selected && css`
    color: ${props => props.selectedTextColor};
    fill: ${props => props.selectedTextColor};
    background-color: ${props => props.selectedBackgroundColor};
  `}
  ${props => props.hovered && css`
    color: ${props => props.hoveredTextColor};
    fill: ${props => props.hoveredTextColor};
    background-color: ${props => props.hoveredBackgroundColor};
  `}
  ${props => props.pressed && css`
    color: ${props => props.pressedTextColor};
    fill: ${props => props.pressedTextColor};
    background-color: ${props => props.pressedBackgroundColor};
  `}
  ${props => props.pressing && css`
    transition-duration: 75ms;
    color: ${props => props.pressedTextColor};
    fill: ${props => props.pressedTextColor};
    background-color: ${props => props.pressedBackgroundColor};
  `}
  ${props => props.disabled && css`
    color: ${props => props.disabledTextColor};
    fill: ${props => props.disabledTextColor};
    background-color: ${props => props.disabledBackgroundColor};
  `}
  ${props => props.releasing && css`
    transition-duration: 225ms;
  `}
  -webkit-tap-highlight-color: transparent;
`;

const ThemedList = styled(Container).attrs(props => ({
  backgroundColor: props.backgroundColor || props.theme.white,
  textColor: props.textColor || props.theme.gray70,
  selectedTextColor: props.selectedTextColor || props.theme.neutral50,
  selectedBackgroundColor: props.selectedBackgroundColor || props.theme.primary10,
  hoveredTextColor: props.hoveredTextColor || props.theme.white,
  hoveredBackgroundColor: props.hoveredBackgroundColor || props.theme.primary30,
  activatedTextColor: props.activatedTextColor || props.theme.white,
  activatedBackgroundColor: props.activatedBackgroundColor || props.theme.primary40,
  pressedTextColor: props.pressedTextColor || props.theme.white,
  pressedBackgroundColor: props.pressedBackgroundColor || props.theme.primary50,
  disabledTextColor: props.disabledTextColor || props.theme.gray40,
  disabledBackgroundColor: props.disabledBackgroundColor || props.theme.white
}))``;

const NeutralList = styled(Container).attrs(props => ({
  backgroundColor: props.backgroundColor || props.theme.white,
  textColor: props.textColor || props.theme.gray70,
  selectedTextColor: props.selectedTextColor || props.theme.gray70,
  selectedBackgroundColor: props.selectedBackgroundColor || props.theme.primary10,
  hoveredTextColor: props.hoveredTextColor || props.theme.gray70,
  hoveredBackgroundColor: props.hoveredBackgroundColor || props.theme.neutral10,
  activatedTextColor: props.activatedTextColor || props.theme.gray70,
  pressedTextColor: props.pressedTextColor || props.theme.gray70,
  pressedBackgroundColor: props.pressedBackgroundColor || props.theme.neutral20,
  disabledTextColor: props.disabledTextColor || props.theme.gray40,
  disabledBackgroundColor: props.disabledBackgroundColor || props.theme.white
}))`
  margin: 2px 4px 2px 4px;
  border-radius: 4px;
  &:first-child {
    margin-top: 4px;
  }
  &:last-child {
    margin-bottom: 4px;
  }
  ${props => props.activated && css`
    box-shadow: inset 0px 0px 0px 2px ${props => props.theme.neutral70};
  `}
`;

export default class ListItem extends PureComponent {

  static defaultProps = {
    variant: 'neutral'
  };

  constructor() {
    super();
    this.state = {
      hovered: false,
      activated: false,
      pressed: false,
      pressing: false,
      releasing: false
    };
    this.ref = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.handlePointerEnter = this.handlePointerEnter.bind(this);
    this.handlePointerLeave = this.handlePointerLeave.bind(this);
    this.handlePointerDown = this.handlePointerDown.bind(this);
    this.handlePointerUp = this.handlePointerUp.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTransitionEnd = this.handleTransitionEnd.bind(this);
  }

  componentDidUpdate() {
    if (this.props.activated && this.ref && this.ref.current) {
      this.ref.current.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
        inline: 'nearest'
      });
    }
  }

  render() {
    const Component = this.props.variant === 'themed' ? ThemedList : NeutralList;
    return (
      <Component
        ref={this.ref}
        {...this.state}
        selected={this.props.selected}
        activated={this.props.activated}
        className={this.props.className}
        backgroundColor={this.props.backgroundColor}
        textColor={this.props.textColor}
        disabled={this.props.disabled}
        disabledTextColor={this.props.disabledTextColor}
        disabledBackgroundColor={this.props.disabledBackgroundColor}
        selectedBackgroundColor={this.props.selectedBackgroundColor}
        selectedTextColor={this.props.selectedTextColor}
        hoveredBackgroundColor={this.props.hoveredBackgroundColor}
        hoveredTextColor={this.props.hoveredTextColor}
        activatedBackgroundColor={this.props.activatedBackgroundColor}
        activatedTextColor={this.props.activatedTextColor}
        pressedBackgroundColor={this.props.pressedBackgroundColor}
        pressedTextColor={this.props.pressedTextColor}
        onClick={this.handleClick}
        onPointerEnter={this.handlePointerEnter}
        onPointerLeave={this.handlePointerLeave}
        onPointerDown={this.handlePointerDown}
        onPointerUp={this.handlePointerUp}
        onTouchMove={this.handleTouchMove}
        onTransitionEnd={this.handleTransitionEnd}
      >
        {this.decorate(this.props.children)}
      </Component>
    );
  }

  decorate(children) {
    return React.Children.map(children, (child) => {
      if (child) {
        return (
          <child.type
            {...child.props}
            selected={this.props.selected}
            hovered={this.state.hovered}
            activated={this.props.activated}
          >
          </child.type>
        );
      }
      return null;
    });
  }

  handleClick(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.onClick && !this.props.disabled) {
      this.props.onClick({
        id: this.props.id
      });
    }
  }

  handlePointerEnter(e) {
    if (e.pointerType === 'mouse' && !this.props.disabled) {
      this.setState({
        hovered: true
      });
      if (this.props.onPointerEnter) {
        this.props.onPointerEnter({
          id: this.props.id
        });
      }
    }
  }

  handlePointerLeave(e) {
    if (e.pointerType === 'mouse' && !this.props.disabled) {
      this.setState({
        hovered: false,
        pressed: false,
        pressing: false,
        releasing: false
      });
      if (this.props.onPointerLeave) {
        this.props.onPointerLeave({
          id: this.props.id
        });
      }
    }
  }

  handlePointerDown(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.props.disabled) {
      this.setState({
        pressed: true,
        pressing: true,
        releasing: false
      });
    }
  }

  handlePointerUp(e) {
    e.stopPropagation();
    if (!this.props.disabled) {
      this.setState(state => {
        if (state.pressing) {
          return {
            pressed: false
          };
        } else {
          return {
            pressed: false,
            releasing: true
          };
        }
      });
    }
  }

  handleTouchMove() {
    if (!this.props.disabled) {
      this.setState({
        pressed: false,
        pressing: false,
        releasing: false
      });
    }
  }

  handleTransitionEnd(e) {
    e.stopPropagation();
    if (e.target === this.ref.current && e.propertyName === 'background-color') {
      this.setState(state => {
        if (state.pressing && !state.pressed) {
          return {
            pressing: false,
            releasing: true
          };
        } else if (state.pressing) {
          return {
            pressing: false
          };
        } else if (state.releasing) {
          return {
            releasing: false
          };
        }
      });
    }
  }

}