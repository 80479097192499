import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { reducer as cartReducer } from '../features/cart';
import { reducer as applicationReducer } from '../features/common';
import api from '../utils/api';

export default function configureStore(history, initialState) {

  const errorHandler = store => next => async action => {
    try {
      return await next(action);
    } catch (e) {
      history.push('/error');
    }
  };

  const middleware = [
    errorHandler,
    thunk.withExtraArgument({ api, history }),
    routerMiddleware(history)
  ];

  const rootReducer = combineReducers({
    router: connectRouter(history),
    application: applicationReducer,
    cart: cartReducer
  });

  const enhancers = [];
  const windowIfDefined = typeof window === 'undefined' ? null : window;
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
