export default class ValidationResult {

  constructor({ valid = true, errorMessage, errors = [] } = {}) {
    this.errors = errors;
    this.errorMessage = errorMessage;
    this.valid = valid;
  }

  getErrorMessage(propertyName) {
    if (propertyName && this.errors) {
      const validationError = this.errors.find(x => x.propertyName === propertyName);
      if (validationError) {
        return validationError.errorMessage;
      }
    }
    return null;
  }

}