import React from 'react';
import styled from 'styled-components';
import { StackLayout, Text } from '../../../ui';

const Container = styled(StackLayout).attrs(props => ({
  orientation: 'horizontal',
  columnGap: '4px',
  notGrow: true,
  align: 'left'
}))``;

const NormalPrice = styled(Text).attrs(props => ({
  variant: props.textVariant || 'subtitle1'
}))`
  color: #234828;
`;

const PriceWithDiscount = styled(Text).attrs(props => ({
  variant: props.textVariant || 'subtitle1'
}))`
  color: #234828;
`;

const OldPrice = styled(Text).attrs(props => ({
  variant: props.textVariant || 'subtitle1'
}))`
  color: rgb(155, 155, 155);
  text-decoration-line: line-through;
`;

export default class Price extends React.PureComponent {

  render() {

    const formatter = new Intl.NumberFormat('lt-LT', {
      style: 'currency',
      currency: 'EUR'
    });

    const hasDiscount = this.props.priceWithoutDiscount || !isNaN(this.props.priceWithoutDiscount);

    return (
      <Container
        size={this.props.size}
        weight={this.props.weight}
      >
        {hasDiscount &&
          <>
           <OldPrice textVariant={this.props.textVariant}>
              {formatter.format(this.props.priceWithoutDiscount)}
            </OldPrice>
            <PriceWithDiscount textVariant={this.props.textVariant}>
              {formatter.format(this.props.price)}
            </PriceWithDiscount>      
          </>
        }
        {!hasDiscount &&
          <NormalPrice textVariant={this.props.textVariant}>
            {formatter.format(this.props.price)}
          </NormalPrice>
        }
      </Container>
    );
  }

}