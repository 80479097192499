import React, { Component } from 'react';

export default class Wash40Icon extends Component {

  render() {
    return (
      <svg width='48' height='48' viewBox='0 0 375 375' className={this.props.className} fill={this.props.color}>
        <path
          fill='none'
          stroke={this.props.color}
          strokeWidth='8'
          d='M 37.456350,106.87084 L 71.853509,278.85663 L 302.16847,278.85663 L 337.60154,106.87084'
        />
        <path
          fill='none'
          stroke={this.props.color}
          strokeWidth='8'
          d='M 46.352165,145.54748 C 69.810441,145.54748 69.810441,124.65767 93.268724,124.65767 C 116.72698,124.65767 116.72698,145.54748 140.18525,145.54748 C 163.64355,145.54748 163.64355,124.65767 187.10180,124.65767 C 210.56007,124.65767 210.56007,145.54748 234.01836,145.54748 C 257.47663,145.54748 257.47663,124.65767 280.93489,124.65767 C 304.39316,124.65767 304.39316,145.54748 327.85144,145.54748'/>
        <path
          d='M 131.25925,123.34062 L 113.32957,151.36015 L 131.25925,151.36015 L 131.25925,123.34062 M 129.39597,117.15312 L 138.32566,117.15312 L 138.32566,151.36015 L 145.81394,151.36015 L 145.81394,157.26640 L 138.32566,157.26640 L 138.32566,169.64140 L 131.25925,169.64140 L 131.25925,157.26640 L 107.56394,157.26640 L 107.56394,150.41093 L 129.39597,117.15312 M 172.77879,121.82890 C 169.12252,121.82895 166.36861,123.63364 164.51707,127.24297 C 162.68893,130.82894 161.77487,136.23128 161.77488,143.45000 C 161.77487,150.64533 162.68893,156.04767 164.51707,159.65703 C 166.36861,163.24297 169.12252,165.03594 172.77879,165.03593 C 176.45845,165.03594 179.21235,163.24297 181.04050,159.65703 C 182.89203,156.04767 183.81781,150.64533 183.81785,143.45000 C 183.81781,136.23128 182.89203,130.82894 181.04050,127.24297 C 179.21235,123.63364 176.45845,121.82895 172.77879,121.82890 M 172.77879,116.20390 C 178.66157,116.20396 183.14985,118.53599 186.24363,123.20000 C 189.36078,127.84066 190.91937,134.59066 190.91941,143.45000 C 190.91937,152.28595 189.36078,159.03595 186.24363,163.70000 C 183.14985,168.34062 178.66157,170.66093 172.77879,170.66093 C 166.89596,170.66093 162.39596,168.34062 159.27879,163.70000 C 156.18503,159.03595 154.63816,152.28595 154.63816,143.45000 C 154.63816,134.59066 156.18503,127.84066 159.27879,123.20000 C 162.39596,118.53599 166.89596,116.20396 172.77879,116.20390'
          transform="scale(1.255735,1.453502)"
        />
      </svg>
    );
  }

}