import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { KeyboardEventsRoot } from '.';

const Filler = styled.div`
  z-index: -1;
`;

class VirtualScroll extends PureComponent {

  static defaultProps = {
    fillerMargin: 400,
    maxItemHeight: 600
  }

  constructor() {
    super();
    this.state = {
      offset: 0
    };
    this.topFillerRef = React.createRef();
    this.bottomFillerRef = React.createRef();
    this.handleIntersectionObserverCallback = this.handleIntersectionObserverCallback.bind(this);
    this.intersectionObserver = new IntersectionObserver(this.handleIntersectionObserverCallback);
  }

  componentDidMount() {
    if (this.topFillerRef.current) {
      this.intersectionObserver.observe(this.topFillerRef.current);
    }
    if (this.bottomFillerRef.current) {
      this.intersectionObserver.observe(this.bottomFillerRef.current);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items) {
      const totalItemCount = this.getTotalItemCount();
      const maxOffset = totalItemCount > 0
        ? totalItemCount - 1
        : 0;
      this.setState(state => {
        if (state.offset > maxOffset) {
          return {
            offset: maxOffset
          };
        }
        return null;
      });
    }
    if (this.topFillerRef.current) {
      this.intersectionObserver.observe(this.topFillerRef.current);
    }
    if (this.bottomFillerRef.current) {
      this.intersectionObserver.observe(this.bottomFillerRef.current);
    }
  }

  componentWillUnmount() {
    this.intersectionObserver.disconnect();
  }

  render() {
    const renderedItemCount = this.getRenderedItemCount();
    const topPadding = this.getTopPadding();
    const bottomPadding = this.getBottomPadding(renderedItemCount);
    return (
      <>
        {!!topPadding &&
          <Filler
            ref={this.topFillerRef}
            style={{
              height: `${topPadding + this.props.fillerMargin}px`,
              marginBottom: `-${this.props.fillerMargin}px`
            }}
          />
        }
        <KeyboardEventsRoot forwardedRef={this.props.forwardedRef}>
          {!!this.props.items?.length && this.props.items.slice(this.state.offset, this.state.offset + renderedItemCount).map((x, index) => {
            const item = this.props.renderItem(x, this.props.itemProps);
            const key = this.state.offset + index;
            return (
              <item.type
                key={key}
                id={key}
                {...this.props.itemProps}
                {...item.props}
              />
            );
          })}
        </KeyboardEventsRoot>
        {!!bottomPadding &&
          <Filler
            ref={this.bottomFillerRef}
            style={{
              height: `${bottomPadding + this.props.fillerMargin}px`,
              marginTop: `-${this.props.fillerMargin}px`
            }}
          />
        }
      </>
    );
  }

  handleIntersectionObserverCallback(entries) {
    this.setState(state => {
      let offset = state.offset;
      for (const entry of entries) {
        if (entry.isIntersecting) {
          if (entry.target === this.topFillerRef.current) {
            if (offset > 0) {
              let delta = entry.boundingClientRect.bottom;
              if (typeof this.props.itemHeight === 'function') {
                while (delta >= 0 && offset > 0) {
                  offset--;
                  delta -= this.props.itemHeight(offset);
                }
              } else {
                offset -= Math.round(delta / this.props.itemHeight) + 1;
                if (offset < 0) {
                  offset = 0;
                }
              }
            }
          } else if (entry.target === this.bottomFillerRef.current) {
            const totalItemCount = this.getTotalItemCount();
            const renderedItemCount = this.getRenderedItemCount();
            const maxOffset = totalItemCount - renderedItemCount;
            if (offset < maxOffset) {
              let delta = entry.rootBounds.height - entry.boundingClientRect.top;
              if (typeof this.props.itemHeight === 'function') {
                while (delta >= 0 && offset < maxOffset) {
                  offset++;
                  delta -= this.props.itemHeight(offset + renderedItemCount - 1);
                }
              } else {
                offset += Math.round(delta / this.props.itemHeight) + 1;
                if (offset > maxOffset) {
                  offset = maxOffset;
                }
              }
            }
          }
        }
      }
      if (offset !== this.state.offset) {
        this.intersectionObserver.disconnect();
      }
      return { offset };
    });
  }

  getTotalItemCount() {
    return this.props.items?.length || 0;
  }

  getRenderedItemCount() {
    let renderedItemCount = 0;
    let renderedHeight = window.innerHeight + this.props.fillerMargin * 2 + this.props.maxItemHeight;
    const totalItemCount = this.getTotalItemCount();
    if (typeof this.props.itemHeight === 'function') {
      let index = this.state.offset;
      while (renderedHeight > 0 && index < totalItemCount) {
        renderedHeight -= this.props.itemHeight(index);
        index++;
        renderedItemCount++;
      }
    } else {
      renderedItemCount = Math.ceil(renderedHeight / this.props.itemHeight);
    }
    return Math.min(renderedItemCount, totalItemCount);
  }

  getTopPadding() {
    let padding = 0;
    const totalItemCount = this.getTotalItemCount();
    if (typeof this.props.itemHeight === 'function') {
      for (let index = 0; index < this.state.offset && index < totalItemCount; index++) {
        padding += this.props.itemHeight(index);
      }
    } else {
      padding = this.props.itemHeight * this.state.offset;
    }
    return padding;
  }

  getBottomPadding(renderedItemCount) {
    let padding = 0;
    const totalItemCount = this.getTotalItemCount();
    if (typeof this.props.itemHeight === 'function') {
      for (let index = this.state.offset + renderedItemCount; index < totalItemCount; index++) {
        padding += this.props.itemHeight(index);
      }
    } else {
      padding = this.props.itemHeight * (totalItemCount - renderedItemCount - this.state.offset);
    }
    return padding;
  }

}

export default React.forwardRef((props, ref) => <VirtualScroll {...props} forwardedRef={ref} />);