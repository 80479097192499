import React, { Component } from 'react';

export default class ExpandMoreIcon extends Component {

  render() {
    return (
      <svg width='24' height='24' viewBox='0 0 24 24' className={this.props.className} fill={this.props.color}>
        <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'/>
        <path d='M0 0h24v24H0z' fill='none'/>
      </svg>
    );
  }

}