import React, { PureComponent } from 'react';
import styled from 'styled-components';
import BaseButton from './base-button';

const Container = styled(BaseButton).attrs(props => ({
  minWidth: '0',
  padding: '0',
  focusedPadding: '0',
  focusedStyle: 'outline',
  textColor: props.textColor || props.theme.primary40,
  backgroundColor: props.backgroundColor || props.theme.transparent,
  hoveredTextColor: props.hoveredTextColor || props.theme.primary30,
  hoveredBackgroundColor: props.hoveredBackgroundColor || props.theme.transparent,
  pressedTextColor: props.pressedTextColor || props.theme.primary50,
  pressedBackgroundColor: props.pressedBackgroundColor || props.theme.transparent,
  focusedBorderColor: props.focusedBorderColor || props.theme.primary50,
  disabledBackgroundColor: props.disabledBackgroundColor || props.theme.transparent,
  progressIndicatorColor: props.progressIndicatorColor || props.theme.primary40
}))`
  text-decoration: none;
`;

export default class LinkButton extends PureComponent {

  render() {
    return (
      <Container {...this.props}>
        {this.props.children}
      </Container>
    );
  }

}