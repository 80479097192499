import React from 'react';
import styled from 'styled-components';
import { StackLayout, Text, CheckIcon, BaseButton, Skeleton } from '../../../ui';
import api from '../../../utils/api';

const Container = styled(StackLayout).attrs(props => ({
  orientation: 'vertical',
  rowGap: '16px'
}))`
  margin: 8px;
`;

const Check = styled(CheckIcon).attrs(props => ({
  color: props.theme.success70
}))``;

export default class PaymentAcceptPage extends React.PureComponent {

  constructor() {
    super();
    this.state = {
      loading: true,
      orderKey: null
    };
    this.handleNavigateToOrderTracking = this.handleNavigateToOrderTracking.bind(this);
  }

  async componentDidMount() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const data = params.get('data');
    const ss1 = params.get('ss1');
    const response = await api.get('api/payment/accept', { data: data, ss1: ss1 });
    if (response?.status === 'Payment successful' || response?.status === 'Redirect successful') {
      this.setState({
        loading: false,
        orderKey: response.orderKey
      });
    } else {
      this.props.history.push({ pathname: '/payment/cancel', search: `?data=${data}&ss1=${ss1}` });
    }
  }

  render() {
    return (
      <Container>
        {this.state.loading
          ?
          <>
            <Skeleton variant='text' height='26px'/>
            <Skeleton variant='text' height='21px'/>
            <Skeleton variant='text' height='21px'/>
            <Skeleton variant='text' height='42px'/>
            <Skeleton height='36px'/>
          </>
          :
          <>
            <StackLayout orientation='horizontal' align='left' columnGap='8px'>
              <Text variant='h4'>Apmokėjimas sėkmingas</Text>
              <Check/>
            </StackLayout>
            <Text variant='body1'>Ačiū, kad pasirikote mūsų gaminius. Užsakyma stengsimės įvydyti kiek įmanoma greičiau.</Text>
            <Text variant='h6'>Užsakymo vykdymo stebėjimas</Text>
            <Text variant='body1'>Jūsų nurodytu elektroniniu pašto adresu išsiuntėmė užsakymo stebėjimo nuoroda. Taip pat galite matyti užsakymo būseną paspaudę mygtuką apačioje.</Text>
            <BaseButton
              text='Užsakymo stebėjimas'
              type='button'
              onClick={this.handleNavigateToOrderTracking}
            />
          </>
        }
      </Container>
    );
  }

  handleNavigateToOrderTracking() {
    this.props.history.push('/order_tracking/' + this.state.orderKey);
  }

}