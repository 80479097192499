import React from 'react';
import { NavLink } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';

const ImageWrapper = styled.div`
  flex-grow: 0;
  position: relative;
  width: 100%;
  padding-top: 100%;
`;

const ImageSkeleton = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.gray10};
`;

const ImageLink = styled(NavLink)`
  flex-grow: 0;
`;

const Image = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default class ShopImage extends React.PureComponent {

  constructor() {
    super();
    this.loaderRef = React.createRef();
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
  }

  render() {
    return (
      <ImageWrapper>
        <ImageSkeleton ref={this.loaderRef} />
        <LazyLoad once offset={600}>
          <ImageLink to={this.props.to}>
            <Image
              alt={this.props.alt}
              src={this.props.src}
              onLoad={this.handleImageLoaded}
              width={734}
              height={1101}
            />
          </ImageLink>
        </LazyLoad>
      </ImageWrapper>
    );
  }

  handleImageLoaded() {
    this.loaderRef.current.remove();
  }

}