import React from 'react';
import styled from 'styled-components';

import { WrapLayout, StackLayout, Text, PhoneIcon, MailIcon, InstagramIcon, FacebookIcon, IconButton, LinkButton } from '../../../ui';

const Wrapper = styled.footer`
  background-color: #e4e4e1;
  margin-top: auto;
`;

const Cotnainer = styled(StackLayout)`
  min-width: 304px;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px 8px;
`;

const Title = styled(Text).attrs(props => ({
  variant: 'body1',
  color: props.theme.neutral00
}))`
  margin-bottom: 16px;
`;

const ContactsText = styled(Text).attrs(props => ({
  variant: 'body2',
  textColor: props.theme.gray70
}))`
`;

const ContactLink = styled.a`
  color: ${props => props.theme.gray70};
  &:hover {
    color:  ${props => props.theme.primary70};
  }
  text-decoration: none;
`;

const PhoneImage = styled(PhoneIcon).attrs(props => ({
  color: props.theme.gray70
}))`
  min-width: 24px;
`;

const MailImage = styled(MailIcon).attrs(props => ({
  color: props.theme.gray70
}))`
  min-width: 24px;
`;

const Link = styled(LinkButton).attrs(props => ({
  textColor: props.theme.gray70,
  hoveredTextColor: props.theme.primary70,
  textVariant: 'body1'
}))`
`;

export default class Footer extends React.PureComponent {

  constructor() {
    super();
    this.handleReturnPolicyClick = this.handleReturnPolicyClick.bind(this);
    this.handleDeliveryClick = this.handleDeliveryClick.bind(this);
    this.handleRulesClick = this.handleRulesClick.bind(this);
    this.handleFacebookClick = this.handleFacebookClick.bind(this);
    this.handleInstagramClick = this.handleInstagramClick.bind(this);
  }

  render() {
    return (
      <Wrapper>
        <Cotnainer>
          <WrapLayout orientation='horizontal' align='center'>
            <StackLayout valign='top'>
              <Title>Informacija</Title>
              <StackLayout valign='top' align='left' rowGap='0'>
                <Link text='Prekių grąžinimas' onClick={this.handleReturnPolicyClick}/>
                <Link text='Pirkimo taisyklės' onClick={this.handleRulesClick}/>
                <Link text='Pristatymas'onClick={this.handleDeliveryClick}/>
              </StackLayout>
            </StackLayout>
            <StackLayout valign='top'>
              <Title>Kontaktai</Title>
              <StackLayout valign='top' notGrow rowGap='8px'>
                <StackLayout orientation='horizontal' align='left' columnGap='8px' valign='center'>
                  <PhoneImage />
                  <ContactsText>Tel.: <ContactLink href='tel:+37064152095'>+37064152095</ContactLink></ContactsText>
                </StackLayout>
                <StackLayout orientation='horizontal' align='left' columnGap='8px' valign='center'>
                  <MailImage />
                  <ContactsText>El. paštas: <ContactLink href='mailto:info@eppe.lt'>info@eppe.lt</ContactLink></ContactsText>
                </StackLayout>
                <StackLayout orientation='horizontal' align='left' columnGap='8px' valign='center'>
                  <ContactsText>Evaldas Zimnickas Ind. veikl. paž. nr. 970642</ContactsText>
                </StackLayout>
              </StackLayout>
            </StackLayout>
            <StackLayout orientation='horizontal' valign='top' align='center' columnGap='8px'>
              <IconButton icon={FacebookIcon} height='64px' onClick={this.handleFacebookClick}/>
              <IconButton icon={InstagramIcon} height='64px' onClick={this.handleInstagramClick}/>
            </StackLayout>
          </WrapLayout>
        </Cotnainer>
      </Wrapper>
    );
  }

  handleFacebookClick() {
    window.location.href = 'https://www.facebook.com/Eppekids';
  }

  handleInstagramClick() {
    window.location.href = 'https://www.instagram.com/eppe_kids/';
  }

  handleReturnPolicyClick() {
    this.props.history.push('/return_policy');
  }

  handleDeliveryClick() {
    this.props.history.push('/delivery');
  }

  handleRulesClick() {
    this.props.history.push('/rules');
  }
}