import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Text from './text';

const Container = styled.div`
  position: absolute;
  background-color: ${props => props.color ? props.color : props.theme.primary40};
  height: 16px;
  min-width: 16px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 4px;
  top: ${props => props.top};
  right: ${props => props.right};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const Content = styled(Text).attrs(props => ({
  variant: 'caption',
  selectionDisabled: true,
  textColor: props.theme.white
}))``;

export default class Badge extends PureComponent {

  static defaultProps = {
    top: '4px',
    right: '4px'
  };

  render() {
    if (this.props.value) {
      let value = this.props.value;
      if (this.props.value >= this.props.maxValue) {
        value = `${this.props.maxValue - 1}+`;
      }
      return (
        <Container top={this.props.top} right={this.props.right} color={this.props.color}>
          <Content>{value}</Content>
        </Container>
      );
    }
    return null;
  }

}