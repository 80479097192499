import React, { PureComponent } from 'react';
import styled from 'styled-components';

const Orientation = {
  Horizontal: 'horizontal',
  Vertical: 'vertical'
};

const Horizontal = styled.div`
  height: 1px;
  background-color: ${props => props.theme.neutral20};
  flex-grow: 0;
`;

const Vertical = styled.div`
  width: 1px;
  background-color: ${props => props.theme.neutral20};
`;

export default class Separator extends PureComponent {

  static defaultProps = {
    orientation: Orientation.Horizontal
  }

  render() {
    if (this.props.orientation === Orientation.Horizontal) {
      return (
        <Horizontal className={this.props.className} />
      );
    } else {
      return (
        <Vertical className={this.props.className} />
      );
    }
  }

}