import React, { Component } from 'react';

export default class CheckIcon extends Component {

  render() {
    return (
      <svg width='24' height='24' viewBox='0 0 24 24' className={this.props.className} fill={this.props.color}>
        <path d='M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z'/>
      </svg>
    );
  }

}