import CartActionTypes from './action-types';
import { CartCheckoutDialog } from './components';
import { openDialog } from '../common';
import { validateObject } from '../../ui';

export const addCartItem = (payload) => (dispatch, getState) => {
  const items = getState()?.cart?.items;
  if (items) {
    let item = items.find(x => x.id === payload.size.id);
    if (item) {
      item.amount++;
      item.totalPrice = item.price * item.amount;
      item.totalPriceWithoutDiscount = item.priceWithoutDiscount * item.amount;
    } else {
      items.push({
        id: payload.size.id,
        name: payload.name,
        size: payload.size.name,
        amount: payload.amount,
        productId: payload.productId,
        productKey: payload.productKey,
        price: payload.size.price,
        priceWithoutDiscount: payload.size.priceWithoutDiscount,
        totalPrice: payload.size.price * payload.amount,
        totalPriceWithoutDiscount: payload.size.priceWithoutDiscount * payload.amount
      });
    }
  }
  dispatch({
    type: CartActionTypes.ADD_CART_ITEM,
    items: items
  });
  dispatch(openDialog(
    {
      type: (CartCheckoutDialog),
      props: {
        product: {
          key: payload.productKey,
          id: payload.productId,
          name: payload.productName,
          title: payload.productTitle
        },
        size: payload.size,
        amount: payload.amount
      }
    }
  ));
  dispatch(getDeliveryPrice());
};

export const deleteCartItem = (payload) => (dispatch, getState) => {
  const items = getState()?.cart?.items;
  const index = items?.findIndex(x => x.id === payload.id);
  if (index > -1) {
    dispatch({
      type: CartActionTypes.DELETE_CART_ITEM,
      items: items.splice(index, 1)
    });
  }
  dispatch(getDeliveryPrice());
};

export const amountIncrease = (payload) => (dispatch, getState) => {
  const items = getState()?.cart?.items;
  items[payload.id].amount++;
  items[payload.id].totalPrice = items[payload.id].price * items[payload.id].amount;
  items[payload.id].totalPriceWithoutDiscount = items[payload.id].priceWithoutDiscount * items[payload.id].amount;
  dispatch({
    type: CartActionTypes.UPDATE_CART_ITEM_AMOUNT,
    items: items
  });
  dispatch(getDeliveryPrice());
};

export const amountDecrease = (payload) => (dispatch, getState) => {
  const items = getState()?.cart?.items;
  items[payload.id].amount--;
  if (items[payload.id].amount <= 0) {
    items[payload.id].amount = 1;
  }
  items[payload.id].totalPrice = items[payload.id].price * items[payload.id].amount;
  items[payload.id].totalPriceWithoutDiscount = items[payload.id].priceWithoutDiscount * items[payload.id].amount;
  dispatch({
    type: CartActionTypes.UPDATE_CART_ITEM_AMOUNT,
    items: items
  });
  dispatch(getDeliveryPrice());
};

export const loadOmnivaLocations = (payload) => async (dispatch, getState, { api }) => {
  const locations = await api.get('/api/omniva/locatios');
  const items = locations?.map(item => { return { id: item.key, text: item.name }; });
  dispatch({
    type: CartActionTypes.FINISH_LOADING_OMNIVA_LOCATIONS,
    locations: items
  });
};

export const selectOmnivaLocation = (payload) => (dispatch) => {
  dispatch({
    type: CartActionTypes.SELECT_CART_OMNIVA_LOCATION,
    omnivaLocation: payload.omnivaLocation
  });
  dispatch(saveCartToLocalStorage());
};

export const searchOmnivaLocation = (payload) => (dispatch, getState) => {
  let items = [];
  if (payload?.searchText && payload?.searchText !== '' && payload?.searchText !== undefined) {
    const regExp = new RegExp(payload.searchText, 'i');
    items = getState().cart.availableOmnivaLocations?.filter(x => x.text.search(regExp) >= 0);
  } else {
    items = getState().cart.availableOmnivaLocations;
  }
  dispatch({
    type: CartActionTypes.SEARCH_CART_OMNIVA_LOCATION,
    filteredOmnivaLocations: items
  });
};

export const selectPaymentType = (payload) => (dispatch) => {
  dispatch({
    type: CartActionTypes.SELECT_CART_PAYMENT_TYPE,
    paymentType: payload.paymentType
  });
  dispatch(saveCartToLocalStorage());
};

export const updateForm = (payload) => (dispatch, getState) => {
  const cart = getState()?.cart;
  cart[payload.propertyName] = payload.propertyValue;
  dispatch({
    type: CartActionTypes.UPDATE_CART_FORM,
    cart: cart
  });
  dispatch(saveCartToLocalStorage());
};

export const checkout = (payload) => async (dispatch, getState, { api }) => {
  const cart = getState()?.cart;
  const validationResult = validateObject(cart, cart?.validationRules);
  dispatch({
    type: CartActionTypes.UPDATE_CART_VALIDATION_RESULT,
    validationResult: validationResult
  });
  if (validationResult.valid) {
    const order = {
      email: cart.customerEmail,
      name: cart.customerName,
      lastName: cart.customerSurname,
      phoneNumber: cart.customerPhone,
      paymentMethod: cart.paymentType,
      deliveryMethod: 'Omniva',
      deliveryLocation: cart.omnivaLocation?.id,
      deliveryLocationDescription: cart.omnivaLocation?.text,
      agreeWithRules: cart.agreeWithRules,
      products: cart.items?.map(item => {
        return {
          amount: item.amount,
          productId: item.productId,
          size: item.size
        };
      })
    };
    var orderResult = await api.post('/api/orders', order);
    if (orderResult?.key) {
      var paymentResult = await api.get('/api/payment', { orderKey: orderResult?.key });
      if (paymentResult) {
        window.location.href = paymentResult;
      }
    }
  }
};

export const cartImageLoaded = (payload) => (dispatch, getState, { api }) => {
  const items = getState().cart?.items;
  dispatch({
    type: CartActionTypes.FINISH_LOAD_CART_IMAGE,
    items: items?.map(item => ({ ...item, imageLoaded: item.id === payload?.id ? true : item.imageLoaded }))
  });
};

export const getDeliveryPrice = () => async (dispatch, getState, { api }) => {
  dispatch({
    type: CartActionTypes.START_LOAD_CART_DELIVERY_PRICE
  });
  const items = getState().cart?.items;
  var price = await api.post('/api/delivery/cost', {
    deliveryMethod: 'Omniva',
    items: items?.map(item => ({ productId: item.productId, size: item.size, amount: item.amount }))
  });
  dispatch({
    type: CartActionTypes.LOAD_CART_DELIVERY_PRICE,
    price: price
  });
  dispatch(saveCartToLocalStorage());
};

export const aggreWithRules = (payload) => (dispatch) => {
  dispatch({
    type: CartActionTypes.CHECK_CART_AGREE_WITH_RULES,
    value: payload.value
  });
};

export const loadCart = (payload) => (dispatch) => {
  const items = payload.cart?.products.map(item => ({
    ...item,
    totalPrice: item.price * item.amount,
    totalPriceWithoutDiscount: item.priceWithoutDiscount * item.amount
  }));
  dispatch({
    type: CartActionTypes.LOAD_CART,
    cart: {
      orderKey: payload.cart?.key,
      customerName: payload.cart?.name,
      customerSurname: payload.cart?.lastName,
      customerPhone: payload.cart?.phoneNumber,
      customerEmail: payload.cart?.email,
      paymentType: payload.cart?.paymentMethod,
      omnivaLocation: payload.cart?.deliveryLocation,
      items: items
    }
  });
  dispatch(saveCartToLocalStorage());
};

export const saveCartToLocalStorage = (payload) => (dispatch, getState) => {
  const cart = getState()?.cart;
  if (cart) {
    const storageCart = {
      orderKey: cart.orderKey,
      customerName: cart.customerName,
      customerSurname: cart.customerSurname,
      customerPhone: cart.customerPhone,
      customerEmail: cart.customerEmail,
      paymentType: cart.paymentType,
      omnivaLocation: cart.omnivaLocation,
      deliveryCost: cart.deliveryCost,
      deliveryCostWithoutDiscount: cart.deliveryCostWithoutDiscount,
      items: cart.items
    };
    window.localStorage.setItem('cart', JSON.stringify(storageCart));
  }
};
