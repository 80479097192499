import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { StackLayout, Text, ErrorIcon, BaseButton, Skeleton } from '../../../ui';
import api from '../../../utils/api';
import { loadCart } from '../../cart';

const Container = styled(StackLayout).attrs(props => ({
  orientation: 'vertical',
  rowGap: '16px'
}))`
  margin: 8px;
`;

const Error = styled(ErrorIcon).attrs(props => ({
  color: props.theme.error50
}))``;

class PaymentCancelPage extends React.PureComponent {

  constructor() {
    super();
    this.state = {
      loading: true,
      orderKey: null
    };
    this.handleNavigateToCart = this.handleNavigateToCart.bind(this);
  }

  async componentDidMount() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const data = params.get('data');
    const ss1 = params.get('ss1');
    const key = params.get('key');
    const response = await api.get(`api/payment/cancel`, { data: data, ss1: ss1, key: key });
    let order = await api.get(`api/orders${response.orderKey}`);
    if (order?.deliveryLocation) {
      const locations = await api.get('/api/omniva/locatios');
      const location = locations?.find(item => item.key === order.deliveryLocation);
      order = {
        ...order,
        deliveryLocation: { id: location.key, text: location.name }
      };
    }
    this.props.loadCart(order);
    this.setState({
      loading: false,
      orderKey: response.orderKey
    });
  }

  render() {
    return (
      <Container>
        {this.state.loading
          ?
          <>
            <Skeleton variant='text' height='26px'/>
            <Skeleton variant='text' height='21px'/>
            <Skeleton height='36px'/>
          </>
          :
          <>
            <StackLayout orientation='horizontal' align='left' columnGap='8px'>
              <Text variant='h4'>Apmokėjimas nepavyko</Text>
              <Error/>
            </StackLayout>
            <Text variant='body1'>Patikrinkite ar viską pasirinkote ir įvedėte teisingai ir bandykite dar kartą</Text>
            <BaseButton
              text='Užsakymo krepšelis'
              type='button'
              onClick={this.handleNavigateToCart}
            />
          </>
        }
      </Container>
    );
  }

  handleNavigateToCart() {
    this.props.history.push('/cart');
  }
}

const mapDispatchToProps = dispatch => ({
  loadCart: (payload) => dispatch(loadCart(payload))
});

export default connect(null, mapDispatchToProps)(PaymentCancelPage);