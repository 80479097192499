import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { WrapLayout } from '../layouts';

const Container = styled(WrapLayout)`
  flex-grow: 0;
`;

export default class ToggleButtonGroup extends PureComponent {

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
  }

  render() {
    return (
      <Container className={this.props.className} orientation='horizontal' columnGap='4px' align='left'>
        {React.Children.map(this.props.children, (child, index) => (
          <child.type
            {...child.props}
            id={'id' in child.props ? child.props.id : index}
            disabled={child.props.disabled || this.props.disabled}
            selected={child.props.selected || this.props.value === child.props.id}
            onClick={e => {
              this.handleChange(e);
              if (child.props.onClick) {
                child.props.onClick(e);
              }
            }}
          >
            {child.props.children}
          </child.type>
        ))}
      </Container>
    );
  }

  handleChange(e) {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

}