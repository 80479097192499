import React, { Component } from 'react';

export default class LockIcon extends Component {

  render() {
    return (
      <svg width='24' height='24' viewBox='0 0 24 24' className={this.props.className} fill={this.props.color}>
        <path d='m17.69915,8l-1,0l0,-2c0,-2.76 -2.24,-5 -5,-5s-5,2.24 -5,5l0,2l-1,0c-1.1,0 -2,0.9 -2,2l0,10c0,1.1 0.9,2 2,2l12,0c1.1,0 2,-0.9 2,-2l0,-10c0,-1.1 -0.9,-2 -2,-2zm-2.9,0l-6.2,0l0,-2c0,-1.71 1.39,-3.1 3.1,-3.1c1.71,0 3.1,1.39 3.1,3.1l0,2z'/>
      </svg>
    );
  }

}