import React, { Component } from 'react';
import styled, { css } from 'styled-components';

const MIN_SCROLLBAR_HEIGHT = 8;

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  ${props => props.$width && css`
    flex-grow: 0;
    flex-basis: auto;
    width: ${props.$width};
  `}
  ${props => props.maxWidth && css`
    max-width: ${props.maxWidth};
  `}
  ${props => props.$height && css`
    height: ${props.$height};
  `}
  ${props => props.backgroundColor && css`
    background-color: ${props.backgroundColor};
  `}
  ${props => props.column && css`
    grid-column-start: ${props => props.column};
  `}
  ${props => props.columnSpan && css`
    grid-column-end: span ${props => props.columnSpan};
  `}
  ${props => props.row && css`
    grid-row-start: ${props => props.row};
  `}
  ${props => props.rowSpan && css`
    grid-row-end: span ${props => props.rowSpan};
  `}
  ${props => props.margin && css`
    margin: ${props => props.margin};
  `}
`;

const Viewport = styled.div`
  flex-grow: 1;
  align-self: stretch;
  margin-right: -80px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  min-height: 100%;
  width: ${props => props.width}px;
  ${props => props.scrollbarPressed && css`
    pointer-events: none;
  `}
`;

const Scrollbar = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 0;
  z-index: 100;
  opacity: 0.4;
  ${props => props.hovered && css`
    opacity: 0.7;
  `}
  ${props => props.pressed && css`
    opacity: 1;
  `}
  transition: opacity 250ms;
  :after {
    content: '';
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border-radius: 4px;
    background-color: ${props => props.theme.gray50};
  }
`;

export default class ScrollableLayout extends Component {

  constructor() {
    super();
    this.state = {
      hovered: false,
      scrollbarPressed: false
    };
    this.containerRef = React.createRef();
    this.viewportRef = React.createRef();
    this.contentRef = React.createRef();
    this.scrollbarRef = React.createRef();
    this.viewportScrollTop = 0;
    this.maxViewportScrollTop = 0;
    this.contentHeight = 0;
    this.scrollbarHeight = 0;
    this.scrollbarTop = 0;
    this.minScrollbarTop = 0;
    this.maxScrollbarTop = 0;
    this.mouseDownClientY = 0;
    this.mouseDownScrollbarTop = 0;
    this.checkContentSizeRequest = null;
    this.handlePointerEnter = this.handlePointerEnter.bind(this);
    this.handlePointerLeave = this.handlePointerLeave.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.checkContentSize = this.checkContentSize.bind(this);
  }

  componentDidMount() {
    this.checkContentSizeRequest = requestAnimationFrame(this.checkContentSize);
    this.reset();
    const container = this.containerRef.current;
    if (container) {
      this.setState({
        width: container.clientWidth
      });
    }
  }

  componentWillUnmount() {
    if (this.checkContentSizeRequest) {
      cancelAnimationFrame(this.checkContentSizeRequest);
      this.checkContentSizeRequest = null;
    }
  }

  render() {
    return (
      <Container
        data-scrollable
        className={this.props.className}
        ref={this.containerRef}
        margin={this.props.margin}
        onPointerEnter={this.handlePointerEnter}
        onPointerLeave={this.handlePointerLeave}
        $width={this.props.width}
        maxWidth={this.props.maxWidth}
        $height={this.props.height}
        backgroundColor={this.props.backgroundColor}
        column={this.props.column}
        columnSpan={this.props.columnSpan}
        row={this.props.row}
        rowSpan={this.props.rowSpan}
      >
        <Viewport
          ref={this.viewportRef}
          onScroll={this.handleScroll}
        >
          {!!this.state.width &&
            <Content
              ref={this.contentRef}
              scrollbarPressed={this.state.scrollbarPressed}
              width={this.state.width}
            >
              {this.props.children}
            </Content>
          }
        </Viewport>
        <Scrollbar
          ref={this.scrollbarRef}
          hovered={this.state.hovered}
          pressed={this.state.scrollbarPressed}
          onMouseDown={this.handleMouseDown}
        />
      </Container>
    );
  }

  reset() {
    const container = this.containerRef.current;
    const viewport = this.viewportRef.current;
    const content = this.contentRef.current;
    const scrollbar = this.scrollbarRef.current;
    if (container && viewport && content && scrollbar) {
      this.viewportScrollTop = viewport.scrollTop;
      this.maxViewportScrollTop = viewport.scrollHeight - viewport.offsetHeight;
      if (this.maxViewportScrollTop > 0) {
        this.scrollbarHeight = Math.max(Math.floor(viewport.offsetHeight * (viewport.offsetHeight / viewport.scrollHeight)), MIN_SCROLLBAR_HEIGHT);
        const containerClientRect = container.getBoundingClientRect();
        const viewportClientRect = viewport.getBoundingClientRect();
        this.minScrollbarTop = viewportClientRect.top - containerClientRect.top;
        this.maxScrollbarTop = this.minScrollbarTop + viewport.offsetHeight - this.scrollbarHeight;
        this.scrollbarTop = this.getScrollbarTopByViewport();
      } else {
        this.scrollbarHeight = 0;
        this.scrollbarTop = 0;
      }
      scrollbar.style.height = `${this.scrollbarHeight}px`;
      scrollbar.style.top = `${this.scrollbarTop}px`;
    }
  }

  handlePointerEnter(e) {
    if (e.pointerType === 'mouse') {
      this.setState({
        hovered: true
      });
    }
  }

  handlePointerLeave(e) {
    if (e.pointerType === 'mouse') {
      this.setState({
        hovered: false
      });
    }
  }

  handleMouseDown(e) {
    e.preventDefault();
    this.setState({
      scrollbarPressed: true
    });
    this.mouseDownClientY = e.clientY;
    this.mouseDownScrollbarTop = this.scrollbarTop;
    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);
  }

  handleMouseUp(e) {
    e.preventDefault();
    this.setState({
      scrollbarPressed: false
    });
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
  }

  handleMouseMove(e) {
    const viewport = this.viewportRef.current;
    const scrollbar = this.scrollbarRef.current;
    if (viewport && scrollbar && this.state.scrollbarPressed) {
      const deltaY = e.clientY - this.mouseDownClientY;
      let scrollbarTop = this.mouseDownScrollbarTop + deltaY;
      scrollbarTop = Math.min(scrollbarTop, this.maxScrollbarTop);
      scrollbarTop = Math.max(scrollbarTop, this.minScrollbarTop);
      if (this.scrollbarTop !== scrollbarTop) {
        this.scrollbarTop = scrollbarTop;
        this.viewportScrollTop = this.getViewportScrollTopByScrollbar();
        viewport.scrollTop = this.viewportScrollTop;
        scrollbar.style.top = `${this.scrollbarTop}px`;
      }
    }
  }

  handleScroll() {
    const viewport = this.viewportRef.current;
    const scrollbar = this.scrollbarRef.current;
    if (viewport && scrollbar && !this.state.scrollbarPressed) {
      this.viewportScrollTop = viewport.scrollTop;
      this.scrollbarTop = this.getScrollbarTopByViewport();
      scrollbar.style.top = `${this.scrollbarTop}px`;
    }
  }

  getScrollbarTopByViewport() {
    return Math.floor(this.viewportScrollTop / this.maxViewportScrollTop * (this.maxScrollbarTop - this.minScrollbarTop) + this.minScrollbarTop);
  }

  getViewportScrollTopByScrollbar() {
    return Math.floor((this.scrollbarTop - this.minScrollbarTop) / (this.maxScrollbarTop - this.minScrollbarTop) * this.maxViewportScrollTop);
  }

  checkContentSize() {
    const content = this.contentRef.current;
    if (content && this.contentHeight !== content.offsetHeight) {
      this.contentHeight = content.offsetHeight;
      this.reset();
    }
    const container = this.containerRef.current;
    if (container && container.clientWidth !== this.state.width) {
      this.setState({
        width: container.clientWidth
      }, () => {
        this.checkContentSizeRequest = requestAnimationFrame(this.checkContentSize);
      });
    } else {
      this.checkContentSizeRequest = requestAnimationFrame(this.checkContentSize);
    }
  }

}