export default function (size) {
  if (size === 'xxxs') {
    return '(min-width: 375px)';
  } else if (size === 'xxs') {
    return '(min-width: 375px)';
  } else if (size === 'xs') {
    return `(min-width: 768px)`;
  } else if (size === 'sm') {
    return `(min-width: 1024px)`;
  } else if (size === 'md') {
    return `(min-width: 1280px)`;
  } else if (size === 'lg') {
    return `(min-width: 1600px)`;
  } else if (size === 'xl') {
    return `(min-width: 1920px)`;
  } else if (size === 'xxl') {
    return `(min-width: 2560px)`;
  }
}