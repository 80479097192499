import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';

const HorizontalAlignment = {
  Stretch: 'stretch',
  Left: 'left',
  Center: 'center',
  Right: 'right'
};

const VerticalAlignment = {
  Stretch: 'stretch',
  Top: 'top',
  Center: 'center',
  Bottom: 'bottom'
};

const Container = styled.div`
  box-sizing: border-box;
  padding: ${props => props.padding};
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: column;
  ${props => props.align === HorizontalAlignment.Stretch && css`
    align-items: stretch;
  `}
  ${props => props.align === HorizontalAlignment.Left && css`
    align-items: flex-start;
  `}
  ${props => props.align === HorizontalAlignment.Center && css`
    align-items: center;
  `}
  ${props => props.align === HorizontalAlignment.Right && css`
    align-items: flex-end;
  `}
  ${props => props.valign === VerticalAlignment.Stretch && css`
    justify-content: flex-start;
    & > * {
      flex-grow: 1;
    }
  `}
  ${props => props.valign === VerticalAlignment.Top && css`
    justify-content: flex-start;
  `}
  ${props => props.valign === VerticalAlignment.Center && css`
    justify-content: center;
  `}
  ${props => props.valign === VerticalAlignment.Bottom && css`
    justify-content: flex-end;
  `}
  ${props => props.$width && css`
    flex-grow: 0;
    flex-basis: auto;
    width: ${props.$width};
  `}
  ${props => props.$height && css`
    height: ${props.$height};
  `}
  ${props => props.backgroundColor && css`
    background-color: ${props.backgroundColor};
  `}
  ${props => props.column && css`
    grid-column-start: ${props => props.column};
  `}
  ${props => props.columnSpan && css`
    grid-column-end: span ${props => props.columnSpan};
  `}
  ${props => props.row && css`
    grid-row-start: ${props => props.row};
  `}
  ${props => props.rowSpan && css`
    grid-row-end: span ${props => props.rowSpan};
  `}
  ${props => props.margin && css`
    margin: ${props => props.margin};
  `}
`;

class BoxLayout extends PureComponent {

  static defaultProps = {
    padding: '16px',
    align: HorizontalAlignment.Stretch,
    valign: VerticalAlignment.Stretch
  }

  render() {
    return (
      <Container
        ref={this.props.forwardedRef}
        className={this.props.className}
        margin={this.props.margin}
        align={this.props.align}
        valign={this.props.valign}
        padding={this.props.padding}
        $width={this.props.width}
        $height={this.props.height}
        backgroundColor={this.props.backgroundColor}
        column={this.props.column}
        columnSpan={this.props.columnSpan}
        row={this.props.row}
        rowSpan={this.props.rowSpan}
      >
        {this.props.children}
      </Container>
    );
  }

}

export default React.forwardRef((props, ref) => <BoxLayout forwardedRef={ref} {...props} />);